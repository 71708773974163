import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { ArrowDownIcon } from 'components/Icons/ArrowDownIcon';
import { useStyles } from './styles';
import PropTypes from 'prop-types';

export const PaymentHistoryActions = ({ row, handleConfirm }) => {
  const classes = useStyles();

  // go to payment and download the invoice
  const navigateToDetail = (payment) => {
    handleConfirm(payment?.original);
  };

  return (
    <Tooltip title="Download invoice">
      <IconButton className={classes.content} onClick={() => navigateToDetail(row)}>
        <ArrowDownIcon size={15} />
      </IconButton>
    </Tooltip>
  );
};

PaymentHistoryActions.propTypes = {
  row: PropTypes.object,
};

PaymentHistoryActions.defaultProps = {
  row: null,
};
