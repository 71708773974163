import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  general: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.3),
    borderRadius: '8px',
  },
  content: {
    backgroundColor: theme.palette.primary[1300],
    border: `1px solid ${theme.palette.primary[1300]}`,
    width: '100%',
    borderRadius: 8,
    height: '100%',
  },
  header: {
    paddingBottom: 8,
    borderBottom: `1px solid ${theme.palette.primary[800]}`,
    marginLeft: theme.spacing(0.7),
    marginRight: theme.spacing(0.7),
    marginTop: theme.spacing(0.7),
  },
  footer: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.3),
  },
});
