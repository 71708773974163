import React, { useEffect, useState, useContext } from 'react';
import { Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { Avatar } from 'components/Avatar';
import { Loader } from 'components/Loader';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { Permissions } from 'pages/Admin/Admins/Permissions/Permissions';
import { resolveUserInitials } from 'lib/resolve-user-initials';
import { useStyles } from './styles';
import OrganizationService from 'services/OrganizationService';
import moment from 'moment';

export const MostActiveMembers = ({ orgId }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setActivePanel, setFullWidth, setHeaderPanels, config } = useContext(SidebarContext);
  const classes = useStyles();

  const getMembers = (id) => {
    setLoading(true);
    OrganizationService.getOrganizationUsers(id).then((res) => {
      const users = res.data.data.map((user) => ({
        ...user,
        lastLogin: user.lastLogin ? moment(user.lastLogin).format('MM/DD/YY') : null,
      }));
      setMembers(users.toSorted((a, b) => new Date(b.lastLogin) - new Date(a.lastLogin)));
      setLoading(false);
    });
  };

  const handleDetails = (member) => {
    const component = config?.header?.find((item) => item.name === 'origin');
    setHeaderPanels([
      {
        name: 'members',
        component: Permissions,
        userId: member.id,
        destination: component?.destination,
        actions: {
          handleClose: () => {
            setActivePanel('');
          },
        },
      },
    ]);
    setFullWidth(true);
    setActivePanel('members');
  };

  useEffect(() => {
    getMembers(orgId);
  }, []);

  return (
    <div className={classes.mActiveMembers}>
      <div>
        <Typography fontSize={16} color="#FFFFFF" fontWeight={700} lineHeight="24px" fontFamily="Lato">
          Most Active Members
        </Typography>
        <Typography fontSize={14} color="#CFCFCF" fontWeight={400} lineHeight="21px" fontFamily="Lato">
          Most activve members of your org.
        </Typography>
      </div>
      {loading && <Loader loading={loading} height={50} size={32} />}
      <ul style={{ height: '92%', overflow: 'auto', marginTop: '20px' }}>
        {!loading &&
          members.map((member) => (
            <li key={member.id} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
              <span style={{ display: 'flex' }}>
                <Avatar
                  initials={resolveUserInitials({ firstName: member.firstName, lastName: member.lastName })}
                  size={35}
                />
                <span>
                  <Typography fontSize={14} color="#FFFFFF" fontWeight={700} lineHeight="21px" fontFamily="Lato">
                    {`${member.firstName} ${member.lastName}`}
                  </Typography>
                  <Typography fontSize={12} color="#CFCFCF" fontWeight={4400} lineHeight="18px" fontFamily="Lato">
                    Last active: {member.lastLogin}
                  </Typography>
                </span>
              </span>
              <Button
                onClick={() => handleDetails(member)}
                sx={{ border: '1px solid #515151', backgroundColor: '#363636' }}
              >
                <FontAwesomeIcon icon={faEllipsisVertical} size="lg" color="#929295" />
              </Button>
            </li>
          ))}
      </ul>
    </div>
  );
};
