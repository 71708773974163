import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TabPanel } from 'components/TabPanel';
import { OrgDashboardHeaderDetail } from './OrgDashboardHeaderDetail';
import { Dashboard } from './Dashboard/Dashboard';
import { Admins } from './Admins/Admins';
import { Members } from './Members/Members';
import { Teams } from './Teams/Teams';
import { useStyles } from './styles';
import { ORGANIZATIONS_TABS } from './lib';

export const OrgDashboard = () => {
  const classes = useStyles();
  const [tab, setTabValue] = useState(ORGANIZATIONS_TABS.DASHBOARD);
  const { org } = useSelector((state) => state.organizations);

  useEffect(() => {
    setTabValue(ORGANIZATIONS_TABS.DASHBOARD);
  }, []);

  return (
    <div className={classes.containerDetail}>
      <OrgDashboardHeaderDetail data={org} tab={tab} setTabValue={(value) => setTabValue(value)} />

      <TabPanel value={tab} index={ORGANIZATIONS_TABS.DASHBOARD}>
        <Dashboard org={org} setTabValue={(value) => setTabValue(value)} />
      </TabPanel>
      <TabPanel value={tab} index={ORGANIZATIONS_TABS.ADMINS}>
        <Admins />
      </TabPanel>
      <TabPanel value={tab} index={ORGANIZATIONS_TABS.MEMBERS}>
        <Members />
      </TabPanel>
      <TabPanel value={tab} index={ORGANIZATIONS_TABS.TEAMS}>
        <Teams />
      </TabPanel>
      <TabPanel value={tab} index={ORGANIZATIONS_TABS.PROJECTS}>
        <Teams />
      </TabPanel>
      <TabPanel value={tab} index={ORGANIZATIONS_TABS.CONTENT}>
        <Teams />
      </TabPanel>
      <TabPanel value={tab} index={ORGANIZATIONS_TABS.MAPS}>
        <Teams />
      </TabPanel>
      <TabPanel value={tab} index={ORGANIZATIONS_TABS.INSPECTIONS}>
        <Teams />
      </TabPanel>
      <TabPanel value={tab} index={ORGANIZATIONS_TABS.PENDING_DELETION}>
        <Teams />
      </TabPanel>
      <TabPanel value={tab} index={ORGANIZATIONS_TABS.ACTION_REQUIRED}>
        <Teams />
      </TabPanel>
      <TabPanel value={tab} index={ORGANIZATIONS_TABS.SETTINGS}>
        <Teams />
      </TabPanel>
    </div>
  );
};
