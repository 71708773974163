import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Button, FormControl, TextField } from '@mui/material';
import { FilterIcon, GridIcon, ListIcon, SearchIcon } from 'components/Icons';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { SidebarContext } from '../SidebarProvider/SidebarProvider';
import { useStyles } from './styles';

export const TopBar = ({
  options,
  tableCardToggle,
  // eslint-disable-next-line no-unused-vars
  handleActions,
  handleSearch,
  handleToggle,
  searchValue,
  activatedFilter,
  sideBarDetail,
  credit,
  filterButton,
}) => {
  const classes = useStyles({ flex: (options && options.length > 0) || tableCardToggle ? 'space-between' : 'end' });
  const [optionValue, setOptionValue] = useState(null);
  const { setActivePanel, setFullWidth } = useContext(sideBarDetail ? SidebarDetailContext : SidebarContext);
  const [tableToggle, setTableToggle] = useState(true);

  const handleOptionChange = (value) => {
    setOptionValue(value);
  };

  const handleLocalToggle = () => {
    setTableToggle(!tableToggle);
    handleToggle(!tableToggle);
  };

  return (
    <div className={credit ? `${classes.bar} ${classes.credit}` : classes.bar}>
      <div>
        {options && options.length > 0 && false && (
          <FormControl className={classes.select}>
            <Autocomplete
              options={options}
              value={optionValue}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} placeholder="Select Option" />}
              onChange={(e, value) => {
                handleOptionChange(value?.value ? value : null);
              }}
            />
          </FormControl>
        )}
        {tableCardToggle &&
          (!tableToggle ? (
            <Button color="primary" variant="contained" className={classes.button} onClick={() => handleLocalToggle()}>
              <ListIcon size={15} />
            </Button>
          ) : (
            <Button
              color="secondary"
              variant="contained"
              className={classes.button}
              onClick={() => handleLocalToggle()}
            >
              <GridIcon size={15} />
            </Button>
          ))}
      </div>
      <div className={classes.filters}>
        <TextField
          variant="outlined"
          placeholder="Search"
          className={classes.input}
          InputProps={{
            startAdornment: <SearchIcon size={20} />,
          }}
          value={searchValue}
          onChange={handleSearch}
        />
        {filterButton && (
          <Button
            size="medium"
            color="primary"
            variant="contained"
            className={`${classes.filterButton} ${activatedFilter && classes.activatedFilter}`}
            onClick={() => {
              setFullWidth(false);
              setActivePanel('search');
            }}
          >
            <FilterIcon size={15} /> Filters {activatedFilter && 'On'}
          </Button>
        )}
      </div>
    </div>
  );
};

TopBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.any,
  tableCardToggle: PropTypes.bool,
  handleActions: PropTypes.func,
  handleSearch: PropTypes.func,
  searchValue: PropTypes.string,
  handleToggle: PropTypes.func,
  activatedFilter: PropTypes.bool,
  sideBarDetail: PropTypes.bool,
  credit: PropTypes.bool,
  filterButton: PropTypes.bool,
};

TopBar.defaultProps = {
  options: [],
  tableCardToggle: false,
  handleActions: () => {},
  handleSearch: () => {},
  handleToggle: () => {},
  searchValue: '',
  activatedFilter: false,
  sideBarDetail: false,
  credit: false,
  filterButton: true,
};
