import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { useStyles } from './styles';
import { v4 } from 'uuid';
import moment from 'moment';
import PropTypes from 'prop-types';

export const PaymentHistoryPDF = ({ payments }) => (
  <Document>
    <Page size="A4" style={useStyles.page}>
      <View style={useStyles.titleSection}>
        <View style={useStyles.left}>
          <Text>Purchase History</Text>
          <Text style={useStyles.date}>Date Exported: {moment().format('llll')}</Text>
        </View>
        <View style={useStyles.right}>
          <Text>CartoVid</Text>
        </View>
      </View>
      {payments.map((payment) => (
        <View style={useStyles.section} key={`${v4()}${new Date().getMilliseconds()}`}>
          <Text style={useStyles.text}>
            Invoice: <Text style={useStyles.normal}>#{payment?.invoiceNo}</Text>
          </Text>
          <Text style={useStyles.text}>
            Purchase Date: <Text style={useStyles.normal}>{moment(payment?.invoiceDate).format('MMM DD, YYYY')}</Text>
          </Text>
          <Text style={useStyles.text}>
            Purchased By: <Text style={useStyles.normal}>{payment?.customerName}</Text>
          </Text>
          <Text style={useStyles.text}>
            Email: <Text style={useStyles.normal}>{payment?.customerEmail}</Text>
          </Text>
          <Text style={useStyles.text}>
            Amount:{' '}
            <Text style={useStyles.normal}>
              {payment?.amountCurrency}&nbsp;${(payment?.amount / 100).toFixed(2)}
            </Text>
          </Text>
          <Text style={useStyles.text}>
            Card Brand: <Text style={useStyles.normal}>{payment?.cardBrand}</Text>
          </Text>
          <Text style={useStyles.text}>
            Card Last Digits: <Text style={useStyles.normal}>**** **** **** {payment?.cardLastDigits}</Text>
          </Text>
          <Text style={useStyles.text}>
            Credits: <Text style={useStyles.normal}>{(payment?.credits - 0).toLocaleString('en-US')}</Text>
          </Text>
        </View>
      ))}
    </Page>
  </Document>
);

PaymentHistoryPDF.propTypes = {
  payments: PropTypes.array,
};

PaymentHistoryPDF.defaultProps = {
  payments: [],
};
