import React, { useState } from 'react';
import { FormControlLabel, Switch, RadioGroup, Radio } from '@mui/material';

export const SettingsDeletion = () => {
  const [deletion, setDeletion] = useState(true);

  return (
    <>
      <FormControlLabel
        onChange={() => setDeletion(!deletion)}
        control={<Switch color="secondary" />}
        label="Override Current Deletion Policy"
      />
      <RadioGroup>
        <div style={{ display: 'flex' }}>
          <FormControlLabel
            control={<Radio color="secondary" />}
            disabled={deletion}
            style={{ marginLeft: 40 }}
            value={5}
            label="5 Days"
          />
          <FormControlLabel
            control={<Radio color="secondary" />}
            disabled={deletion}
            style={{ marginLeft: 40 }}
            value={7}
            label="1 Week"
          />
        </div>
        <div style={{ display: 'flex' }}>
          <FormControlLabel
            control={<Radio color="secondary" />}
            disabled={deletion}
            style={{ marginLeft: 40, marginTop: 10 }}
            value={14}
            label="2 Weeks"
          />
          <FormControlLabel
            control={<Radio color="secondary" />}
            disabled={deletion}
            style={{ marginLeft: 30, marginTop: 10 }}
            value={30}
            label="1 Month"
          />
        </div>
      </RadioGroup>
    </>
  );
};
