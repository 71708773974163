import React from 'react';
import { Svg } from './Svg';
import PropTypes from 'prop-types';

export const VisaIcon = ({ size }) => (
  <Svg viewBox="0 0 46 32" size={size} fill="none">
    <rect x="0.5" y="0.5" width="45" height="31" rx="5.5" fill="white" />
    <rect x="0.5" y="0.5" width="45" height="31" rx="5.5" stroke="#E1E1E1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#172B85"
      d="M14.3341 21.1451H11.5877L9.52833 13.0573C9.43058 12.6852 9.22304 12.3563 8.91774 12.2013C8.15584 11.8118 7.31628 11.5017 6.40039 11.3454V11.034H10.8245C11.4351 11.034 11.893 11.5017 11.9694 12.045L13.0379 17.879L15.7829 11.034H18.4529L14.3341 21.1451ZM19.9794 21.1451H17.3857L19.5214 11.034H22.1151L19.9794 21.1451ZM25.4707 13.8351C25.547 13.2905 26.0049 12.9791 26.5392 12.9791C27.3788 12.9009 28.2933 13.0573 29.0565 13.4455L29.5145 11.2685C28.7512 10.9571 27.9117 10.8008 27.1498 10.8008C24.6324 10.8008 22.8007 12.2013 22.8007 14.1451C22.8007 15.6238 24.0982 16.4003 25.0141 16.868C26.0049 17.3344 26.3865 17.6458 26.3102 18.1122C26.3102 18.8118 25.547 19.1232 24.7851 19.1232C23.8692 19.1232 22.9533 18.89 22.1151 18.5004L21.6571 20.6787C22.573 21.0669 23.5639 21.2233 24.4798 21.2233C27.3024 21.3001 29.0565 19.9009 29.0565 17.8008C29.0565 15.1561 25.4707 15.0011 25.4707 13.8351ZM38.1337 21.1451L36.0743 11.034H33.8623C33.4043 11.034 32.9464 11.3454 32.7937 11.8118L28.9802 21.1451H31.6502L32.1831 19.6677H35.4637L35.769 21.1451H38.1337ZM34.2439 13.7569L35.0058 17.5676H32.8701L34.2439 13.7569Z"
    />
  </Svg>
);

VisaIcon.propTypes = {
  size: PropTypes.number,
};

VisaIcon.defaultProps = {
  size: 46,
};
