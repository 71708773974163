import React, { useEffect, useState } from 'react';
import { Typography, Button } from '@mui/material';
import { Loader } from 'components/Loader/Loader';
import PropTypes from 'prop-types';
import BillingService from 'services/BillingService';
import AccountService from 'services/AccountService';
import { useStyles } from '../styles';

export const AvailableCredits = ({ expanded, onBuyCredits }) => {
  const [loading, setLoading] = useState(false);
  const [average, setAverage] = useState({});
  const [account, setAccount] = useState({});
  const classes = useStyles();

  const getInformation = () => {
    setLoading(true);
    Promise.all([BillingService.getCreditAvg(), AccountService.getAccount()])
      .then((responses) => {
        const [averageResponse, accountResponse] = responses;
        setAverage(averageResponse?.data?.data);
        setAccount(accountResponse?.data?.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(true);
        throw new Error('Error fetching the information');
      });
  };

  useEffect(() => {
    getInformation();
    return () => {
      setAverage({});
      setAccount({});
    };
  }, []);

  return (
    <div className={classes.content}>
      {loading ? (
        <Loader loading={loading} height={57} size={32} />
      ) : (
        <>
          <div className={classes.contentHeader}>
            <div className={classes.titleOne}>Available Credits</div>
            <div style={{ marginTop: '5px', marginBottom: '7px' }} className={classes.titleSubHeading}>
              Keep track of your remaining credits.
            </div>
          </div>

          {expanded && (
            <div className={classes.contentExpand}>
              <div className={classes.contentCredits}>
                <Typography mb={0.5} variant="h1">
                  {(account?.creditLimit - account?.creditsUsed).toLocaleString('en-US', { numberingSystem: true })}
                </Typography>
              </div>
              <Typography mb={0.5}>
                Average Credit Used Per Day:{' '}
                <div className={classes.contentCreditsAverage}>
                  {average?.daily &&
                  average?.daily[0]?.avg_credits_used_daily &&
                  average?.daily[0]?.avg_credits_used_daily !== null
                    ? average?.daily[0]?.avg_credits_used_daily.toLocaleString()
                    : 0}
                </div>
              </Typography>
              <Typography mb={0.5}>
                Average Credit Used Per Month:{' '}
                <div className={classes.contentCreditsAverage}>
                  {average?.monthly &&
                  average?.monthly[0]?.avg_credits_used_monthly &&
                  average?.monthly[0]?.avg_credits_used_monthly !== null
                    ? average?.monthly[0]?.avg_credits_used_monthly.toLocaleString()
                    : 0}
                </div>
              </Typography>
            </div>
          )}

          <div className={`${classes.borderBottom} ${classes.contentFooter}`}>
            <Button variant="contained" color="secondary" onClick={onBuyCredits}>
              Add Credits Now
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

AvailableCredits.propTypes = {
  expanded: PropTypes.bool,
  onBuyCredits: PropTypes.func,
};

AvailableCredits.defaultProps = {
  expanded: false,
  onBuyCredits: () => {},
};
