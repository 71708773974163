import { StyleSheet } from '@react-pdf/renderer';

export const useStyles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    color: 'black',
    padding: 10,
    paddingTop: 15,
  },
  section: {
    margin: 5,
    padding: 10,
  },
  titleSection: {
    margin: 5,
    padding: 10,
    borderBottom: '1px solid black',
    fontWeight: 'heavy',
    fontSize: '20px',
  },
  left: {
    marginBottom: -20,
    width: 450,
  },
  right: {
    textAlign: 'right',
  },
  date: {
    fontSize: '10px',
    color: '#454646',
  },
  text: {
    margin: 5,
    fontSize: '14px',
  },
  normal: {
    color: '#454646',
  },
});
