import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  // -------------------------- success alert
  successAlert: {
    border: `1px solid ${theme.palette.success[500]}`,
    backgroundColor: theme.palette.success[100],
  },
  successAlertIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.success[800],
    '& svg, & button': {
      color: theme.palette.success[800],
    },
  },
  successAlertTitle: {
    color: `${theme.palette.success[900]}!important`,
    fontWeight: 'bold',
  },
  successAlertText: {
    color: `${theme.palette.success[800]}!important`,
  },
  // -------------------------- error alert
  errorAlert: {
    border: `1px solid ${theme.palette.warning[500]}`,
    backgroundColor: theme.palette.warning[100],
  },
  errorAlertIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.warning[800],
    '& svg, & button': {
      color: theme.palette.warning[800],
    },
  },
  errorAlertTitle: {
    color: `${theme.palette.warning[900]}!important`,
    fontWeight: 'bold',
  },
  errorAlertText: {
    color: `${theme.palette.warning[800]}!important`,
  },
  // -------------------------- dialog actions
  actions: {
    padding: '1rem',
    display: 'flex',
    width: '100%',
  },
  actionsContent: {
    display: 'flex',
    width: '100%',
  },
});
