import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  warningAlert: {
    border: `1px solid ${theme.palette.warning[500]}`,
    backgroundColor: theme.palette.warning[100],
  },
  warningAlertIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.warning[800],
    '& svg, & button': {
      color: theme.palette.warning[800],
    },
  },
  warningAlertTitle: {
    color: `${theme.palette.warning[900]}!important`,
    fontWeight: 'bold',
  },
  warningAlertText: {
    color: `${theme.palette.warning[800]}!important`,
  },
});
