import React from 'react';
import { Typography, Button } from '@mui/material';
import { LayersIcon, ImageCheckIcon, InfoCircleIcon, AlertTriangleIcon } from 'components/Icons';
import { useStyles } from './styles';
import { ORGANIZATIONS_TABS } from '../lib';
import PropTypes from 'prop-types';

export const QuickActions = ({ setTabValue }) => {
  const classes = useStyles();
  return (
    <div className={classes.generalContent}>
      <div>
        <Typography fontSize={16} color="#FFFFFF" fontWeight={700} lineHeight="24px" fontFamily="Lato">
          Quick Actions
        </Typography>
        <Typography fontSize={14} color="#CFCFCF" fontWeight={400} lineHeight="21px" fontFamily="Lato">
          Get to the details fast.
        </Typography>
      </div>
      <div className={`${classes.quickActions}`}>
        <div>
          <div
            style={{
              padding: '15px 0px',
              width: '100%',
              display: 'grid',
              gridTemplateColumns: '50% 50%',
            }}
          >
            <Button
              onClick={() => setTabValue(ORGANIZATIONS_TABS.PROJECTS)}
              sx={{ margin: '6px 3px 6px 0px' }}
              color="primary"
            >
              <LayersIcon />
              <Typography sx={{ marginLeft: '10px' }}>Active Projects</Typography>
            </Button>
            <Button
              onClick={() => setTabValue(ORGANIZATIONS_TABS.INSPECTIONS)}
              sx={{ margin: '6px 0px 6px 6px' }}
              color="primary"
            >
              <ImageCheckIcon />
              <Typography sx={{ marginLeft: '10px' }}>Active Inspections</Typography>
            </Button>
            <Button
              onClick={() => setTabValue(ORGANIZATIONS_TABS.ACTION_REQUIRED)}
              sx={{ margin: '6px 3px 0px 0px' }}
              color="primary"
            >
              <InfoCircleIcon />
              <Typography sx={{ marginLeft: '10px' }}>Action Notices</Typography>
            </Button>
            <Button
              onClick={() => setTabValue(ORGANIZATIONS_TABS.PENDING_DELETION)}
              sx={{ margin: '6px 0px 0px 6px' }}
              color="primary"
            >
              <AlertTriangleIcon />
              <Typography sx={{ marginLeft: '10px' }}>Deletion Notices</Typography>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

QuickActions.propTypes = {
  setTabValue: PropTypes.func,
};

QuickActions.defaultProps = {
  setTabValue: () => {},
};
