import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BillingService from 'services/BillingService';

export const getPayments = createAsyncThunk('paymentHistory/getPyments', async (filters) => {
  try {
    const response = await BillingService.getPayments({ ...filters });
    return response.data;
  } catch {
    throw new Error('Error during request for the payment history...');
  }
});

const initialState = {
  data: [],
  totalCount: 0,
  loading: true,
  error: null,
  filters: {
    page: 1,
    page_size: 10,
  },
};

const paymentHistorySlice = createSlice({
  name: 'paymentHistory',
  initialState,
  reducers: {
    resetPayments: () => initialState,
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setFilters(state, action) {
      const filters = { ...state.filters, ...action.payload, page: 1 };
      state.filters = filters;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPayments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPayments.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.totalCount = action.payload.meta?.totalCount;
      state.loading = false;
    });
    builder.addCase(getPayments.rejected, (state) => {
      state.loading = true;
      state.data = [];
      state.error = 'Unable to load the payment history records';
    });
  },
});

export const { setPageFilter, setFilters, resetPayments } = paymentHistorySlice.actions;

export default paymentHistorySlice.reducer;
