import React from 'react';
import { Typography, Grid } from '@mui/material';
import { Card } from 'components/Card';
import { LastUploads } from './LastUploads';
import { MostActiveProjects } from './MostActiveProjects';
import { QuickActions } from './QuickActions';
import { MostActiveMembers } from './MostActiveMembers';
import { useStyles } from './styles';
import BannerOrgs from './banner_orgs.png';
import PropTypes from 'prop-types';

export const Dashboard = ({ org, setTabValue }) => {
  const classes = useStyles();
  return (
    <Card noPadding>
      <div className={classes.content}>
        <Grid spacing={0} container>
          <Grid xs={12} sm={12} md={12} lg={8.5} xl={8.5} item>
            {/* banner */}
            <div className={classes.titlePanel}>
              <span>
                <Typography fontSize={26} color="#E1E1E1" fontWeight={700} fontFamily="Lato">
                  {org.name}
                </Typography>
                <Typography fontSize={14} color="#CFCFCF" fontWeight={400} lineHeight="21px" fontFamily="Lato">
                  Org Dashboard
                </Typography>
              </span>
              <img src={BannerOrgs} alt="banner_orgs" width="52%" />
            </div>

            <Grid spacing={0} container>
              {/* lastest uploads */}
              <Grid xs={12} sm={12} lg={6} xl={6} item>
                <LastUploads orgId={org.id} />
              </Grid>

              <Grid xs={12} sm={12} lg={6} xl={6} item>
                <Grid xs={12} sm={12} lg={12} xl={12} item>
                  <QuickActions setTabValue={(value) => setTabValue(value)} />
                </Grid>
                <Grid xs={12} sm={12} lg={12} xl={12} item>
                  <MostActiveProjects orgId={org.id} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12} sm={12} md={12} lg={3.5} xl={3.5} item>
            {/* most active members */}
            <Grid item xs>
              <MostActiveMembers orgId={org.id} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

Dashboard.propTypes = {
  setTabValue: PropTypes.func,
};

Dashboard.defaultProps = {
  setTabValue: () => {},
};
