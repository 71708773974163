import PropTypes from 'prop-types';
import React from 'react';
import { Table as MuiTable, TableContainer } from '@mui/material';
import { useStyles } from './styles';

export const Table = ({ children, className, height, credit, payment }) => {
  const classes = useStyles();

  return (
    <TableContainer
      component="div"
      sx={{
        overflowX: 'auto',
        ...(height && { flex: 1, height: '100vh', overflowY: 'auto' }),
        ...(credit && { height: 'calc(100vh - 375px)' }),
        ...(payment && { height: 'calc(100vh - 315px)', borderRadius: '8px' }),
      }}
    >
      <MuiTable className={className || classes.table}>{children}</MuiTable>{' '}
    </TableContainer>
  );
};

Table.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  className: PropTypes.string,
  height: PropTypes.bool,
  credit: PropTypes.bool,
  payment: PropTypes.bool,
};

Table.defaultProps = {
  className: '',
  height: false,
  credit: false,
  payment: false,
};
