import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { InfoIcon } from 'components/Icons';
import { useStyles } from './styles';
import PropTypes from 'prop-types';

export const ModalConfirmPayment = ({ paymentPending, credit, handleConfirm }) => {
  const classes = useStyles();

  return (
    <Dialog open={paymentPending === 'pending'} maxWidth="xs" fullWidth>
      <DialogTitle>
        <ModalHeaderIcon icon={InfoIcon} text="Confirm your purchase amount." />
      </DialogTitle>
      <DialogContent>{`You've selected ${credit.credits} for ${credit.equivalent}.`}</DialogContent>

      <div className={classes.actions}>
        <div className={classes.actionsContent} style={{ marginRight: 5 }}>
          <Button onClick={() => handleConfirm('cancelled')} color="primary" variant="contained" fullWidth>
            Cancel
          </Button>
        </div>

        <div className={classes.actionsContent} style={{ marginLeft: 5 }}>
          <Button onClick={() => handleConfirm('accepted')} color="secondary" variant="contained" fullWidth>
            Confirm Order
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

ModalConfirmPayment.propTypes = {
  paymentPending: PropTypes.string,
  credit: PropTypes.object,
  handleConfirm: PropTypes.func,
};

ModalConfirmPayment.defaultProps = {
  paymentPending: '',
  credit: {},
  handleConfirm: () => {},
};
