import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { IconButton, Typography } from '@mui/material';
import { useSortBy, useTable } from 'react-table';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { EyeIcon2, PencilIcon2 } from 'components/Icons';
import { DeleteButton } from 'components/Observation/ObservationModal/DeleteButton';
import { Loader } from 'components/Loader';
import { setObservation } from 'slices/observationsReducer';
import { useStyles } from '../styles';
import { ObservationModal } from 'components/Observation/ObservationModal/ObservationModal';

const tableColumns = () => [
  {
    Header: 'Date',
    id: 'createdAt',
    accessor: (rowData) => {
      const { createdAt } = rowData;
      return createdAt ? moment(createdAt).format('MM/DD/YY') : 'date not found';
    },
  },
  {
    Header: 'Title/Description',
    accessor: (rowData) => {
      const { name, description } = rowData;
      return (
        <>
          {name}
          <Typography>Description. {description}</Typography>
        </>
      );
    },
  },
];

// eslint-disable-next-line no-unused-vars
export const ProjectAssetObservations = ({ asset }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { observationsFilter: observations } = useSelector((state) => state.assetObservations);
  const { isAssetLoading } = useSelector((state) => state.profileProjectAssets);
  const [onEdit, setOnEdit] = useState({
    open: false,
    row: null,
  });
  const columns = React.useMemo(() => tableColumns(), []);

  const table = useTable(
    {
      columns,
      data: observations,
    },
    useSortBy
  );

  // open observation detail
  const handleViewObservationOpen = (observation) => {
    dispatch(setObservation(observation));
  };

  const openEdit = (row) => {
    setOnEdit({
      open: true,
      row,
    });
  };

  const ObservationActions = ({ row }) => (
    <div className={classes.observationIcons}>
      <IconButton sx={{ padding: '10px', borderRadius: '0' }} onClick={() => handleViewObservationOpen(row.original)}>
        <EyeIcon2 size={20} />
      </IconButton>
      <IconButton sx={{ padding: '10px', borderRadius: '0', margin: '0 1px' }} onClick={() => openEdit(row.original)}>
        <PencilIcon2 size={20} />
      </IconButton>
      <DeleteButton observation={row.original} icon />
    </div>
  );

  ObservationActions.propTypes = {
    row: PropTypes.object.isRequired,
  };

  return (
    <div className={classes.observationsContainer}>
      {isAssetLoading ? (
        <Loader height={50} loading size={32} />
      ) : (
        <>
          <GeneralTable
            table={table}
            rowComponent={ObservationActions}
            selectRow={false}
            actionHeaderText="More"
            dragAndDropEnabled
          />
        </>
      )}
      {onEdit.open && (
        <ObservationModal
          isOpen
          handleClose={() => setOnEdit({ open: false, row: null })}
          observationMap={onEdit.row}
          container="sidebar-panel"
        />
      )}
    </div>
  );
};

ProjectAssetObservations.propTypes = {
  asset: PropTypes.object,
};

ProjectAssetObservations.defaultProps = {
  asset: null,
};
