import React, { useState, useEffect } from 'react';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import { Button, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { ComingSoon } from 'components/ComingSoon/ComingSoon';
import { Card } from 'components/Card/Card';
import { MapSimplePoint } from 'components/MapSimplePoint/MapSimplePoint';
import { useStyles } from './styles';
import { SettingsCard } from './SettingsCard';
import { SettingsDeletion } from './SettingsDeletion';
import { SettingsRetention } from './SettingsRetention';
import AccountService from 'services/AccountService';

export const AdminSecurity = () => {
  const classes = useStyles();
  const [account, setAccount] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    const response = await AccountService.getAccount();
    setAccount(response?.data?.data);
    setLoading(false);
  }, []);

  return (
    <AccountSettingsContainer title={<AdminHeaderTitle buttonName="ADMINISTRATION" section="SETTINGS" />}>
      <Card noPadding>
        {(process.env.REACT_APP_HIDE_SETTINGS === true || process.env.REACT_APP_HIDE_SETTINGS === 'true') && (
          <ComingSoon />
        )}
        {(process.env.REACT_APP_HIDE_SETTINGS === false || process.env.REACT_APP_HIDE_SETTINGS === 'false') && (
          <div style={{ height: 'auto', overflow: 'auto' }}>
            <Grid container>
              <Grid item xs={12} sm={12} lg={6} xl={6}>
                <SettingsCard title="Inspections">
                  <Typography>
                    Enabling Inspections will allow Org Admins and Project Managers to create and assign inspections to
                    project content.
                  </Typography>
                  <div style={{ marginTop: 'inherit' }}>
                    <div className={classes.inspections}>
                      <FormControlLabel
                        control={<Switch color="secondary" />}
                        style={{ marginLeft: 5 }}
                        label="Enable"
                      />
                    </div>
                  </div>
                </SettingsCard>

                <SettingsCard style={{ width: '97%' }} title="External Buckets">
                  <Typography>Connect an external storage resource to a project within CartoVid.</Typography>
                  <Typography>
                    Supperted cloud services include Amazon S3, Google Cloud Storage or Azure Blob.
                  </Typography>
                  <div style={{ marginTop: 'inherit' }}>
                    <Button variant="contained" color="primary" fullWidth disableElevation>
                      Open External Buckets Page
                    </Button>
                  </div>
                </SettingsCard>

                <SettingsCard style={{ width: '97%' }} title="Auto-Assign Location">
                  <MapSimplePoint />
                  <div style={{ marginTop: 'inherit' }}>
                    <Typography>
                      Content with no spatial data will automatically be assigned to this location.
                    </Typography>
                    <Typography>This can be overridden at the org level and project level.</Typography>
                  </div>
                </SettingsCard>
              </Grid>

              <Grid item xs={12} sm={12} lg={6} xl={6}>
                <SettingsCard title="Deletion Policy">
                  <Typography>
                    Select your Account&apos;s default deletion policy. This is the amount of time a project or
                    individual piece of content that has been deleted isrecoverable. This can be overriden at the org
                    level. (System default is 1 week.)
                  </Typography>
                  <div style={{ marginTop: 'inherit' }}>
                    <SettingsDeletion />
                  </div>
                </SettingsCard>

                <SettingsCard title="Retention Policy">
                  <Typography>
                    Select your Account&apos;s default retention policy. When a project reaches term, it will move to
                    &apos;Archived Storage&apos;. This can be overridden at the org level and project level. (System
                    default is 3 Years.)
                  </Typography>
                  <div style={{ marginTop: 'inherit' }}>
                    <SettingsRetention account={account} loading={loading} />
                  </div>
                </SettingsCard>

                <SettingsCard title="Archived Projects">
                  <Typography>
                    Archived projects have been moved to long-term storage. Restoring projects has a higher data
                    transfer cost.
                  </Typography>
                  <div style={{ marginTop: 'inherit', width: '100%' }}>
                    <Button variant="contained" color="primary" fullWidth disableElevation>
                      Open Archived Projects Page
                    </Button>
                  </div>
                </SettingsCard>
              </Grid>
            </Grid>
          </div>
        )}
      </Card>
    </AccountSettingsContainer>
  );
};
