import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { WidgetHeader } from 'components/WidgetHeader/WidgetHeader';
import { useSidebarDetail } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { ModalInviteAccountLevelAdmin } from 'smartComponents/ModalInviteAccountLevelAdmin';
import { ModalNewUser } from 'smartComponents/ModalNewUser';
import { ModalOrganizationNew } from 'smartComponents/ModalOrganizationNew';
import { Grid, Typography } from '@mui/material';
import { ModalProjectNew } from 'smartComponents/ModalProjectNew';
import { ModalComingSoon } from 'smartComponents/ModalComingSoon/ModalComingSoon';
import { useSelector, useDispatch } from 'react-redux';
import { getOrganizations } from 'slices/organizationReducer';
import { fetchUsers } from 'slices/adminAdminsReducer';
import { getUsers } from 'slices/userReducer';
import { getProjects } from 'slices/allProjectReducer';
import { fetchTeams } from 'slices/adminTeamsReducer';
import { getAdminContents } from 'slices/contentAdminReducer';
import { ActionButton } from './ActionButton';
import { ModalSuccessPayment } from 'smartComponents/ModalCreditsPurchasing';
import { ModalWarning } from 'smartComponents/ModalWarning';
import { PaymentHistory } from 'pages/Admin/Billing';
import { useStyles } from './styles';
import AccountService from 'services/AccountService';
import BillingService from 'services/BillingService';

export const QuickActionWidget = () => {
  const classes = useStyles();
  const [orgModalOpenState, setOrgModalOpenState] = React.useState(false);
  const [inviteAdminModalState, setInviteAdminModalState] = React.useState(false);
  const [openProjectNew, setOpenProjectNew] = React.useState(false);
  const [openAddUser, setOpenAddUser] = React.useState(false);
  const [openCominSoon, setOpenCominSoon] = React.useState(false);
  const [openNoSeatsAvailable, setOpenNoSeatsAvailable] = React.useState(false);
  const { orgsCount, filters: orgFilters } = useSelector((state) => state.organizations);
  const { usersCount, filters: adminFilters } = useSelector((state) => state.adminAdmins);
  const { userCount, filters: usrFilters } = useSelector((state) => state.users);
  const { totalCount, filters: projFilters } = useSelector((state) => state.allProjects);
  const { teamCount, filters: teamFilters } = useSelector((state) => state.adminTeams);
  const { assetCount: uploadCount, filters: uploadFilters } = useSelector((state) => state.adminContents);
  const [creditsChoosen, setCreditsChoosen] = React.useState('');
  const [openPaymentSuccess, setOpenPaymentSuccess] = React.useState('');
  const [paymentError, setPaymentError] = React.useState('');

  const [account, setAccount] = React.useState({});

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const handlePayment = async () => {
    const query = new URLSearchParams(location.search);
    if (query.has('paymentSuccess') && query.has('tk') && query.has('stps')) {
      const payment = query.get('paymentSuccess');
      if (payment === 'true') {
        // validate and update the payment
        const validatePayment = await BillingService.getPayment(query.get('tk'));
        const { status } = validatePayment.data?.data;
        if (status === 'pending') {
          const billing = await BillingService.updateBill(query.get('tk'), { stps: query.get('stps') });
          const { credits } = billing.data?.data;
          if (billing.data.status === 'ok') {
            // update the credits
            await AccountService.updateCredits(parseFloat(credits));
            setOpenPaymentSuccess(true);
            setCreditsChoosen(credits);
          }
        }
      } else if (payment === 'false') {
        setPaymentError(true);
      }
      history.replace({ search: '' });
    }
  };

  // add the sidebar detail for the payments page
  const { displayPanel, setHeaderPanels, setFullWidth } = useSidebarDetail({
    open: false,
    config: {
      onClose: () => {},
    },
  });

  const adminAccount = async () => {
    const response = await AccountService.getAccount();
    setAccount(response.data.data);
  };

  useEffect(() => {
    handlePayment();
    adminAccount();
  }, []);

  const organizationModalToggle = (bool) => {
    setOrgModalOpenState(bool);
  };

  const handleInviteAdminConfirm = () => {
    setInviteAdminModalState(false);
  };

  const handleInviteAdminCancel = () => {
    setInviteAdminModalState(false);
  };

  const handleAddUser = async () => {
    setOpenAddUser(false);
  };

  const handleCancelAddUser = () => {
    setOpenAddUser(false);
  };

  const handleCloseSuccessPayment = () => {
    setOpenPaymentSuccess(false);
  };

  const handleCloseErrorPayment = () => {
    setPaymentError(false);
  };

  const loadData = () => {
    const sendFilters = {
      ...orgFilters,
      allOrgByProjectId: true,
      sort_by: Object.values(orgFilters.sort_by),
    };
    dispatch(getOrganizations(sendFilters));
  };

  const fetchData = () => {
    const sendFiltersAdmin = {
      ...adminFilters,
      sort_by: Object.values(adminFilters.sort_by),
    };
    const sendFiltersUsrs = {
      ...usrFilters,
      sort_by: Object.values(usrFilters.sort_by),
    };
    const sendFiltersProjs = {
      ...projFilters,
      sort_by: Object.values(projFilters.sort_by),
    };
    const sendFiltersTeams = {
      ...teamFilters,
      sort_by: Object.values(teamFilters.sort_by),
    };
    const sendFiltersUploads = {
      ...uploadFilters,
      sort_by: Object.values(uploadFilters.sort_by),
    };
    dispatch(fetchUsers(sendFiltersAdmin));
    dispatch(getUsers(sendFiltersUsrs));
    dispatch(getProjects(sendFiltersProjs));
    dispatch(fetchTeams(sendFiltersTeams));
    dispatch(getAdminContents(sendFiltersUploads));
  };

  useEffect(() => {
    loadData();
    fetchData();
  }, []);

  return (
    <div className={`${classes.generalContent} ${classes.fullHeight}`}>
      <WidgetHeader title="Quick Actions" subTitle="Get to the details fast." />
      <Grid container spacing={0.5}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <ActionButton
            title="Current Orgs"
            subTitle={`${orgsCount ?? 0}`}
            buttonText="Create New"
            onClick={() => organizationModalToggle(true)}
          />
          {orgModalOpenState && (
            <ModalOrganizationNew isOpen={orgModalOpenState} handleOpen={organizationModalToggle} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <ActionButton
            title="Account Admins"
            subTitle={`${usersCount ?? 0}`}
            buttonText="Add Admin"
            onClick={() => setInviteAdminModalState(true)}
          />
          {inviteAdminModalState && (
            <ModalInviteAccountLevelAdmin
              isOpen={inviteAdminModalState}
              onConfirm={handleInviteAdminConfirm}
              onCancel={handleInviteAdminCancel}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <ActionButton
            title="System Users"
            subTitle={`${userCount ?? 0}`}
            buttonText="Add User"
            onClick={() => {
              if (account?.seatLimit > usersCount) {
                setOpenAddUser(true);
              } else {
                setOpenNoSeatsAvailable(true);
              }
            }}
          />
          {openAddUser && (
            <ModalNewUser isOpen={openAddUser} onConfirm={handleAddUser} onCancel={handleCancelAddUser} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <ActionButton
            title="Projects"
            subTitle={`${totalCount ?? 0}`}
            buttonText="Create New"
            onClick={() => setOpenProjectNew(true)}
          />
          {openProjectNew && (
            <ModalProjectNew
              isModalOpen={openProjectNew}
              onConfirm={() => setOpenProjectNew(false)}
              onCancel={() => setOpenProjectNew(false)}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <ActionButton
            title="Teams"
            subTitle={`${teamCount ?? 0}`}
            buttonText="Visit page"
            onClick={() => history.push('/admin/teams')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <ActionButton
            title="Uploads"
            subTitle={`${uploadCount ?? 0}`}
            buttonText="Visit page"
            onClick={() => history.push('/admin/content')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ActionButton
            title="Payment History"
            subTitle=""
            buttonText="View"
            onClick={() => {
              // setting the payment configuration
              setHeaderPanels([
                {
                  name: 'payments',
                  component: PaymentHistory,
                  actions: {
                    handleClose: () => {
                      displayPanel('');
                    },
                  },
                },
              ]);
              setFullWidth(true);
              displayPanel('payments');
            }}
          />
        </Grid>
      </Grid>
      {openCominSoon && <ModalComingSoon onCancel={() => setOpenCominSoon(false)} />}
      {openPaymentSuccess && (
        <ModalSuccessPayment
          success={openPaymentSuccess}
          credits={creditsChoosen}
          handleClose={() => handleCloseSuccessPayment()}
        />
      )}
      {paymentError && (
        <ModalWarning
          handleClose={() => handleCloseErrorPayment()}
          open={paymentError}
          title="Transaction has been cancelled"
          content={
            <>
              <Typography>
                The payment has been cancelled or had an error, please check your details in case you want to proceed
                and try again.
              </Typography>
            </>
          }
        />
      )}
      {openNoSeatsAvailable && (
        <ModalWarning
          handleClose={() => setOpenNoSeatsAvailable(false)}
          open={openNoSeatsAvailable}
          title="You're Nearing Your Seat Limit"
          content={
            <>
              <Typography>
                Your account currently has a seat limit of
                <span style={{ fontWeight: 'bold' }}>{account?.seatLimit ?? 0}</span>.
              </Typography>
              <Typography>
                The number of seats in use is <span style={{ fontWeight: 'bold' }}>{userCount ?? 0}</span>
              </Typography>
              <Typography>Please contact your Account Billing</Typography>
              <Typography>Coordinator to increase the number if appropiate.</Typography>
            </>
          }
        />
      )}
    </div>
  );
};
