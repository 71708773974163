import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BillingService from 'services/BillingService';
import { formatDateRange, formatSortBy } from './helpers';

export const getCreditUsage = createAsyncThunk('credit-usage/getCreditUsage', async (filters) => {
  try {
    const filtersOpts = {
      ...formatSortBy(filters.sort_by),
      page: filters.page,
      page_size: filters.page_size,
    };
    if (filters.date_range != null) {
      const dates = formatDateRange(filters.date_range);
      filtersOpts.startDate = dates?.createdAt?.gte;
      filtersOpts.endDate = dates?.createdAt?.lte;
    }
    if (filters?.types?.length) {
      filtersOpts.types = JSON.stringify(filters.types);
    }
    if (filters?.search?.length) {
      filtersOpts.search = filters.search;
    }
    const response = await BillingService.getCreditUsage({ ...filtersOpts });
    return response.data;
  } catch {
    throw new Error('Error during request for the credits usage...');
  }
});

export const getCreditDetail = createAsyncThunk('credit-usage/getCreditDetail', async (filters) => {
  try {
    const response = await BillingService.getCreditDetail(filters);
    return response.data;
  } catch {
    throw new Error('Error during request for the credit detail...');
  }
});

export const preloadFilterDatasets = createAsyncThunk(
  'credit-usage/preloadFilterDatasets',
  async () => {
    try {
      const response = await BillingService.getCreditUsage({});
      return response.data;
    } catch {
      throw new Error('Error during request for the filter data...');
    }
  },
  {
    condition: (_, { getState }) => getState().creditUsage.panels.search.loaded === false,
  }
);

const initialState = {
  records: [],
  panels: {
    detail: {},
    loading: true,
    search: {
      loaded: false,
      types: [],
    },
    filters: {
      page: 1,
      page_size: 10,
      search: '',
    },
  },
  totalCount: 0,
  loading: true,
  error: null,
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    search: '',
    types: [],
    date_range: null,
  },
};

const creditUsageSlice = createSlice({
  name: 'credit-usage',
  initialState,
  reducers: {
    resetCredits: () => initialState,
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setPageFilterDetail(state, action) {
      state.panels.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
      state.filters.page = 1;
    },
    setSearchDetail(state, action) {
      state.panels.filters.search = action.payload;
      state.panels.filters.page = 1;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;
      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setFilterValue(state, { payload }) {
      const { param, value } = payload;
      if (value === '' || value === null) {
        delete state.filters[param];
        return;
      }
      state.filters[param] = payload.value;
    },
    setFilters(state, action) {
      const filter = { ...state.filters, ...action.payload, page: 1 };
      state.filters = filter;
    },
    setCreditInfo(state, action) {
      state.panels.detail.info = action.payload;
    },
    setDetailData(state) {
      state.panels.detail.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCreditUsage.fulfilled, (state, { payload }) => {
      state.records = payload.data;
      state.totalCount = payload.meta.totalCount;
      state.loading = false;
    });
    builder.addCase(getCreditUsage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCreditUsage.rejected, (state) => {
      state.loading = true;
      state.records = [];
      state.error = 'unable to load records';
    });
    // detail
    builder.addCase(getCreditDetail.fulfilled, (state, action) => {
      state.panels.detail.data = action.payload.data;
      state.panels.detail.totalCount = action.payload.meta.totalCount;
      state.panels.loading = false;
    });
    builder.addCase(getCreditDetail.pending, (state) => {
      state.panels.loading = true;
    });
    builder.addCase(getCreditDetail.rejected, (state) => {
      state.panels.loading = true;
      state.panels.detail = {};
      state.error = 'Unable to load the records';
    });
    // filters
    builder.addCase(preloadFilterDatasets.pending, (state) => {
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.fulfilled, (state, action) => {
      state.panels.search.loaded = true;
      const types = [...new Set(action.payload.data.map((item) => item.credits_type))].map((item) => ({
        label: item,
        value: item.toLowerCase(),
      }));
      state.panels.search.types = types;
    });
    builder.addCase(preloadFilterDatasets.rejected, (state) => {
      state.panels.search.loaded = false;
      state.panels.search.types = [];
    });
  },
});

export const {
  setPageFilter,
  setPageFilterDetail,
  setSortBy,
  setSearch,
  setSearchDetail,
  setFilterValue,
  setFilters,
  setCreditInfo,
  setDetailData,
  resetCredits,
} = creditUsageSlice.actions;

export default creditUsageSlice.reducer;
