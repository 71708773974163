export const ORGANIZATIONS_TABS = {
  DASHBOARD: 0,
  ADMINS: 1,
  MEMBERS: 2,
  TEAMS: 3,
  PROJECTS: 4,
  CONTENT: 5,
  MAPS: 6,
  INSPECTIONS: 7,
  PENDING_DELETION: 8,
  ACTION_REQUIRED: 9,
  SETTINGS: 10,
};
