import ApiService from './ApiService';

export default class AccountService {
  static getCreditUsage = async (filters) => ApiService.get(`/credits/all`, {}, { params: { ...filters } });

  static getCreditDetail = async (filters) => ApiService.get(`/credits/details`, {}, { params: filters });

  static getCreditAvg = async () => ApiService.get('credits/average');

  static getCreditGraph = async () => ApiService.get('credits/graph');

  static getCreditGraphCalc = async (graphFilters) =>
    ApiService.get('credits/graphCalc', {
      startDate: graphFilters.startDate,
      endDate: graphFilters.endDate,
      granularity: graphFilters.granularity,
    });

  static getPayments = async (filters) => ApiService.get('/billing', {}, { params: filters });

  static getPayment = async (transactionId) => ApiService.get(`/billing/${transactionId}`);

  static addBill = async (data) => ApiService.post(`/billing`, data);

  static getPaymentsPerAccount = async (acct) => ApiService.get(`/billing/${acct}`);

  static getAllPayments = async () => ApiService.get(`/billing`);

  static updateBill = async (transactionId, data) => ApiService.patch(`/billing/${transactionId}`, data);

  static create = async (data) => ApiService.post('/billing', data);
}
