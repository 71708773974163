import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { ArrowRightIcon } from 'components/Icons/ArrowRightIcon';
import { PropTypes } from 'prop-types';
import { useStyles } from './styles';

export const BillingActions = ({ row, handleConfirm }) => {
  const classes = useStyles();

  // go to billing detail page
  const navigateToDetail = (credit) => {
    handleConfirm(credit);
  };

  return (
    <Tooltip title="View Details">
      <IconButton className={classes.content} onClick={() => navigateToDetail(row?.original)}>
        <ArrowRightIcon size={15} />
      </IconButton>
    </Tooltip>
  );
};

BillingActions.propTypes = {
  row: PropTypes.object,
};

BillingActions.defaultProps = {
  row: null,
};
