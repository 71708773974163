import React from 'react';
import { Typography } from '@mui/material';
import { useGlobalStyles } from 'styles';
import { useStyles } from './styles';
import PropTypes from 'prop-types';

export const Item = ({ title, subHeader }) => {
  const globalStyles = useGlobalStyles();
  const classes = useStyles();

  return (
    <div className={classes.itemTitle}>
      <div className={classes.itemSubHeader}>
        <Typography>{title}</Typography>
        <Typography className={globalStyles.greyTypography}>{subHeader}</Typography>
      </div>
    </div>
  );
};

Item.propTypes = {
  title: PropTypes.string,
  subHeader: PropTypes.string,
};

Item.defaultProps = {
  title: '',
  subHeader: '',
};
