import PropTypes from 'prop-types';
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { AlertTriangleIcon } from 'components/Icons';

export const dialogMessageAction = {
  delete: 'delete',
};

export const DialogMessage = ({
  icon: IconComponent,
  iconColor,
  title,
  isOpen,
  onCancel,
  onConfirm,
  confirmText,
  activeBtn,
  content,
  action,
}) => {
  // ---------------- validate close
  const close = () => {
    if (onCancel && onConfirm) {
      onCancel();
    } else if (onCancel && !onConfirm) {
      onCancel();
    } else if (onConfirm && !onCancel) {
      onConfirm();
    }
  };

  return (
    <Dialog open={isOpen} maxWidth="xs" onClose={close}>
      <DialogTitle>
        <ModalHeaderIcon
          icon={dialogMessageAction.delete === action ? AlertTriangleIcon : IconComponent}
          color={dialogMessageAction.delete === action ? 'warning' : iconColor}
        />
        <Typography variant="h4">{title}</Typography>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions sx={{ padding: 1, paddingTop: 1 }}>
        <Grid container spacing={1}>
          {onCancel && (
            <Grid item xs={onConfirm ? 6 : 12}>
              <Button color="primary" variant="contained" onClick={onCancel} size="large" fullWidth>
                Cancel
              </Button>
            </Grid>
          )}
          {onConfirm && (
            <Grid item xs={onCancel ? 6 : 12}>
              <Button
                color={dialogMessageAction.delete === action ? 'danger' : 'secondary'}
                variant="contained"
                onClick={onConfirm}
                disabled={activeBtn}
                fullWidth
              >
                {dialogMessageAction.delete === action ? 'Yes, Delete' : confirmText}
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
DialogMessage.propTypes = {
  /**
   * The icon is an svg component that is passed a className and size.
   */
  icon: PropTypes.any,
  /**
   * The title is the text that is displayed on the screen
   */
  title: PropTypes.any,
  /**
   * is a boolean that checks if the modal is open or not
   */
  isOpen: PropTypes.any,
  /**
   * this is what action is needed to be handled when clicking confirm
   */
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  activeBtn: PropTypes.bool,
  content: PropTypes.node,
  action: PropTypes.any,
  iconColor: PropTypes.string,
};

DialogMessage.defaultProps = {
  icon: null,
  title: '',
  isOpen: false,
  onConfirm: null,
  onCancel: null,
  confirmText: 'Confirm',
  activeBtn: false,
  content: null,
  action: null,
  iconColor: '',
};
