import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  svg: (props) => ({
    width: `${props.size}px`,
    height: 'auto',
  }),
});

export const Svg = ({ size, viewBox, fill, children, onFocus, onBlur, onClick }) => {
  const classes = useStyles({ size });
  return (
    //  eslint-disable-next-line react/jsx-filename-extension
    <svg viewBox={viewBox} fill={fill} className={classes.svg} onFocus={onFocus} onBlur={onBlur} onClick={onClick}>
      {children}
    </svg>
  );
};

Svg.propTypes = {
  size: PropTypes.number,
  viewBox: PropTypes.string,
  fill: PropTypes.string,
  children: PropTypes.node,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
};

Svg.defaultProps = {
  size: 24,
  viewBox: '',
  fill: '',
  children: null,
  onFocus: () => {},
  onBlur: () => {},
  onClick: () => {},
};
