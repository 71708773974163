import ApiService from './ApiService';

export default class OrganizationService {
  static getOrganizations = (filters) => ApiService.get('/organizations', filters);

  static getSingleOrganization = (orgId) => ApiService.get(`/organizations/${orgId}`);

  static getOrganizationAdminsRoles = (orgId, orgRole) =>
    ApiService.get(`/organizations/${orgId}/users?participationLevel=${orgRole}`);

  static getOrganizationUserRoles = (orgId, userId) => ApiService.get(`/organizations/${orgId}/users/${userId}/roles`);

  // Used to Filter Organization Users
  static getOrganizationUsers = (orgId, filters) => ApiService.get(`/organizations/${orgId}/users?`, filters);

  static saveOrganization = (data) => ApiService.post('/organizations', data);

  static addUser = (orgId, data) => ApiService.post(`/organizations/${orgId}/users`, data);

  static deactivateOrganization = async (orgId) => ApiService.post(`/organizations/${orgId}/deactivate`);

  /**
   * Update  the org roles for a user.
   *
   * @param {string} orgId
   * @param {string} userId
   * @param {number[]} roles
   * @returns
   */

  static updateUserRoles = async (orgId, userId, roles) =>
    ApiService.post(`/organizations/${orgId}/users/${userId}/roles`, { roles });

  /**
   * Update the org information.
   *
   * @param {string} orgId
   * @param {string[]} data
   * @returns
   */
  static updateOrganizationInfo = async (orgId, data) => ApiService.patch(`/organizations/${orgId}`, data);

  static getLatestUploads = async (orgId, filters) => ApiService.get(`organizations/${orgId}/latest-uploads`, filters);
}
