import React, { useCallback, useEffect, useState, useContext } from 'react';
import AccountService from 'services/AccountService';
import OrganizationService from 'services/OrganizationService';
import ProjectService from 'services/ProjectService';
import TeamService from 'services/TeamService';
import UserService from 'services/UserService';
import { Permissions as Perms } from 'lib/permissions';
import { PermGuard } from 'components/Guards/PermGuard';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { Typography, Button, Grid, InputLabel, TextField, Divider } from '@mui/material';
import { useStyles } from './styles';
import { CloseIcon } from 'components/Icons';
import { PropTypes } from 'prop-types';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import libPhone, { validatePhoneNumberLength, isValidPhoneNumber, isPossiblePhoneNumber } from 'libphonenumber-js';
import { FormTextArea } from '../../../../components/FormTextArea/FormTextArea';
import { UuidButton } from '../../../../components/UuidButton/UuidButton';
import { PermissionsPanelV2 } from './Panels/PermissionsPanelV2';
import { MoreThanIcon } from '../../../../components/Icons/MoreThanIcon';

// eslint-disable-next-line func-names
Yup.addMethod(Yup.string, 'phoneNumber', function (errorMessage) {
  // eslint-disable-next-line func-names
  return this.test('test-number-valid', errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) {
      return true;
    }

    const strValue = value === null ? '' : String(value);

    const isValidLength = validatePhoneNumberLength(strValue, 'US');

    if (isValidLength === 'TOO_SHORT') {
      return createError({ path, message: `${path} is too short` });
    }
    if (isValidLength === 'TOO_LONG') {
      return createError({ path, message: `${path} is too long` });
    }

    const isValid = isValidPhoneNumber(strValue, 'US');
    if (!isValid) {
      return createError({ path, message: `${path} does not have a correct format` });
    }

    const isPossible = isPossiblePhoneNumber(strValue, 'US');
    if (!isPossible) {
      return createError({ path, message: `${path} is not a possible number` });
    }

    const phoneNumber = libPhone(strValue, 'US');
    if (phoneNumber) {
      return true;
    }

    return createError({ path, message: errorMessage });
  });
});

const validationSchema = Yup.object({
  firstName: Yup.string()
    .max(40)
    .trim()
    .min(1, 'First Name must contain at least one character')
    .matches(/^[A-Za-z. ]+$/, 'Please enter valid First Name')
    .required('First Name is required'),
  lastName: Yup.string()
    .max(40)
    .trim()
    .min(1, 'First Name must contain at least one character')
    .matches(/^[A-Za-z. ]+$/, 'Please enter valid Last Name')
    .required('Last Name is required'),
  description: Yup.string().max(255).nullable(),
  phone: Yup.string().phoneNumber().nullable(),
});

export const Permissions = ({ userId, handleClose }) => {
  const classes = useStyles();
  const { config } = useContext(SidebarContext);
  const [userIsLoading, setUserIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [userUpdated, setUserUpdated] = useState(false);
  const component = config?.header?.find((item) => item.name === 'members');

  const [userRoles, setUserRoles] = useState({
    accounts: [],
    organizations: [],
    projects: [],
    teams: [],
  });

  const fetchUserRoles = (id) => {
    UserService.getUserRoles(id).then((res) => setUserRoles(res.data.data));
  };

  /**
   *
   * @param {'accounts' | 'organizations' | 'projects' | 'teams'} type
   * @param {{ id: string }} entity
   * @param {Array<any>} roles
   */
  const updateRoles = useCallback(
    (type, entity, roles) => {
      setUserRoles((oldRoles) => {
        const newRoles = { ...oldRoles };
        newRoles[type] = newRoles[type].map((account) => {
          if (account.id === entity.id) {
            account.roles = roles;
          }

          return account;
        });

        return newRoles;
      });
    },
    [setUserRoles]
  );

  const handleAccountRoleEdit = (entity, roles) => {
    AccountService.updateAccountUserRoles(userId, roles).then((res) => updateRoles('accounts', entity, res.data.data));
  };

  const handleOrgRoleEdit = (entity, roles) => {
    OrganizationService.updateUserRoles(entity.id, userId, roles).then((res) =>
      updateRoles('organizations', entity, res.data.data)
    );
  };

  const handleProjectRoleEdit = (entity, roles) => {
    ProjectService.updateUserOrTeamRoles(entity.id, userId, roles).then((res) =>
      updateRoles('projects', entity, res.data.data)
    );
  };

  const handleTeamRoleEdit = (entity, roles) => {
    TeamService.updateUserRoles(entity.id, userId, roles).then((res) => updateRoles('teams', entity, res.data.data));
  };

  const editUserInfo = async (values) => {
    setLoading(true);
    const phone = !values.phone ? null : libPhone(values.phone, 'US').formatInternational();

    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone,
      description: values.description,
    };

    UserService.updateAccountUser(userId, data)
      .then(() => {
        setLoading(false);
        setUserUpdated(true);
      })
      .catch((err) => {
        const result = err.response.data.message;
        setSubmitError(result);
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      firstName: userData.firstName || '',
      lastName: userData.lastName || '',
      description: userData.description || null,
      phone: userData.phone ? libPhone(userData.phone, 'US').formatNational() : null,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit(values) {
      editUserInfo(values);
    },
  });

  const fetchUserAccount = (id) => {
    setUserIsLoading(true);
    UserService.getUser(id).then((res) => {
      setUserData(res.data.data);
      setUserIsLoading(false);
    });
  };

  useEffect(() => {
    let id = userId;
    // get the user identifier from the context
    if (component) {
      const { userId: userIdContext } = component;
      id = userIdContext;
    }
    fetchUserAccount(id);
    fetchUserRoles(id);
  }, []);

  const reduceRoles = (roles) =>
    roles.reduce(
      (acc, val) => [
        ...acc,
        ...val.roles.map((el) => ({
          id: el.grantId,
          roleId: el.roleId,
          displayName: el.displayName,
          entity: { name: val.name, id: val.id },
        })),
      ],
      []
    );

  return (
    <div className={classes.containerDetail}>
      <div className={classes.header}>
        {!userIsLoading ? (
          <>
            <Typography style={{ fontSize: '16px' }}>
              Edit User
              <span style={{ color: '#acacac', padding: '0 10px' }}>
                <MoreThanIcon size={15} />
              </span>
              <span style={{ color: '#acacac', fontSize: '14px' }}>
                {`${userData?.firstName || ''} ${userData?.lastName || ''}`}
              </span>
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                // validate if exist the functions if not validate if has context
                if (component) {
                  const { actions } = component;
                  // closing the sidebar with the context
                  actions.handleClose();
                } else {
                  handleClose(userUpdated);
                }
              }}
              className={classes.headerButton}
            >
              <CloseIcon size={22} />
            </Button>
          </>
        ) : (
          <LoadingOverlay loading={userIsLoading} />
        )}
      </div>
      <div className={classes.userName}>
        <Typography style={{ marginBottom: '4px', fontSize: '20px' }}>
          {userData.firstName} {userData.lastName}
        </Typography>
        <UuidButton data={userData} />
      </div>
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid item xs={12} className={classes.inputForm}>
              <InputLabel>First Name</InputLabel>
              <TextField
                variant="outlined"
                placeholder="First Name"
                name="firstName"
                disabled={loading}
                type="text"
                {...formik.getFieldProps('firstName')}
                error={!!(formik.touched.firstName && formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
                required
                sx={{ width: '85%' }}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputForm}>
              <InputLabel>Last Name</InputLabel>
              <TextField
                variant="outlined"
                placeholder="Last Name"
                name="lastName"
                type="text"
                disabled={loading}
                {...formik.getFieldProps('lastName')}
                error={!!(formik.touched.lastName && formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
                required
                fullWidth
                sx={{ width: '85%' }}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputForm}>
              <InputLabel>Phone</InputLabel>
              <TextField
                variant="outlined"
                placeholder="Phone"
                name="phone"
                disabled={loading}
                type="text"
                {...formik.getFieldProps('phone')}
                error={!!(formik.touched.phone && formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone ? formik.errors.phone : null}
                sx={{ width: '85%' }}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.inputForm}>
            <InputLabel>Bio</InputLabel>
            <FormTextArea
              placeholder="Bio"
              rows="5"
              id="description"
              name="description"
              {...formik.getFieldProps('description')}
              disabled={loading}
              error={!!(formik.touched.description && formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description ? formik.errors.description : null}
              required
              style={{ width: '90%', height: '100%' }}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {submitError && <Typography color="error">{submitError}.</Typography>}
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end', margin: '10px 0' }}>
          <Button color="secondary" variant="contained" onClick={formik.handleSubmit} size="large">
            Save Changes
          </Button>
        </Grid>
        <div className={classes.content}>
          <Divider component="div" sx={{ backgroundColor: 'gray', opacity: '.5' }} />

          <PermissionsPanelV2
            onAdd={fetchUserRoles}
            user={userData}
            roles={reduceRoles(userRoles.teams)}
            onEdit={handleTeamRoleEdit}
            roleType="team"
          />
          <Divider component="div" sx={{ backgroundColor: 'gray', opacity: '.2' }} />

          <PermissionsPanelV2
            onAdd={fetchUserRoles}
            user={userData}
            roles={reduceRoles(userRoles.organizations)}
            onEdit={handleOrgRoleEdit}
            roleType="org"
          />
          <Divider component="div" sx={{ backgroundColor: 'gray', opacity: '.2' }} />

          <PermissionsPanelV2
            onAdd={fetchUserRoles}
            user={userData}
            roles={reduceRoles(userRoles.projects)}
            onEdit={handleProjectRoleEdit}
            roleType="project"
          />
          <Divider component="div" sx={{ backgroundColor: 'gray', opacity: '.2' }} />

          <PermGuard scopes={[Perms.ACCOUNT_USER_GRANT]}>
            {userRoles.accounts.length > 0 && (
              <>
                <PermissionsPanelV2
                  onAdd={fetchUserRoles}
                  user={userData}
                  roles={reduceRoles(userRoles.accounts)}
                  onEdit={handleAccountRoleEdit}
                  roleType="account"
                />
                <Divider component="div" sx={{ backgroundColor: 'gray', opacity: '.1' }} />
              </>
            )}
          </PermGuard>
        </div>
      </div>
    </div>
  );
};

Permissions.propTypes = {
  userId: PropTypes.string,
  handleClose: PropTypes.func,
};

Permissions.defaultProps = {
  userId: '',
  handleClose: () => {},
};
