import React from 'react';
import { Svg } from './Svg';
import PropTypes from 'prop-types';

export const ArrowNarrowDownIcon = ({ size }) => (
  <Svg viewBox="0 0 16 16" size={size} fill="none">
    <path
      d="M8 2.66602V13.3327M8 13.3327L12 9.33268M8 13.3327L4 9.33268"
      stroke="#9E9E9E"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

ArrowNarrowDownIcon.propTypes = {
  size: PropTypes.number,
};

ArrowNarrowDownIcon.defaultProps = {
  size: 16,
};
