import React from 'react';
import { Svg } from './Svg';
import PropTypes from 'prop-types';

export const MasterCardIcon = ({ size }) => (
  <Svg viewBox="0 0 46 32" size={size} fill="none">
    <rect x="0.5" y="0.5" width="45" height="31" rx="5.5" fill="white" />
    <rect x="0.5" y="0.5" width="45" height="31" rx="5.5" stroke="#E1E1E1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9053 22.4396C21.3266 23.7704 19.2787 24.5737 17.0409 24.5737C12.0478 24.5737 8 20.5741 8 15.6404C8 10.7066 12.0478 6.70703 17.0409 6.70703C19.2787 6.70703 21.3266 7.51036 22.9053 8.84109C24.484 7.51036 26.5319 6.70703 28.7697 6.70703C33.7628 6.70703 37.8106 10.7066 37.8106 15.6404C37.8106 20.5741 33.7628 24.5737 28.7697 24.5737C26.5319 24.5737 24.484 23.7704 22.9053 22.4396Z"
      fill="#ED0006"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9053 22.4396C24.8492 20.8011 26.0818 18.363 26.0818 15.6404C26.0818 12.9178 24.8492 10.4796 22.9053 8.84108C24.484 7.51036 26.5319 6.70703 28.7697 6.70703C33.7628 6.70703 37.8106 10.7066 37.8106 15.6404C37.8106 20.5741 33.7628 24.5737 28.7697 24.5737C26.5319 24.5737 24.484 23.7704 22.9053 22.4396Z"
      fill="#F9A000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.905 22.4403C24.8489 20.8018 26.0815 18.3636 26.0815 15.641C26.0815 12.9185 24.8489 10.4803 22.905 8.8418C20.9611 10.4803 19.7285 12.9185 19.7285 15.641C19.7285 18.3636 20.9611 20.8018 22.905 22.4403Z"
      fill="#FF5E00"
    />
  </Svg>
);

MasterCardIcon.propTypes = {
  size: PropTypes.number,
};

MasterCardIcon.defaultProps = {
  size: 46,
};
