import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  container: {
    position: 'relative',
    height: '95vh',
    bottom: 0,
    backgroundColor: theme.palette.secondary[1300],
    borderLeft: `1px solid ${theme.palette.secondary[700]}`,
    zIndex: 1000,
    transform: `translateX('110%')`,
    transition: 'transform 0.3s ease-out',
    overflow: 'hidden',
  },
  header: {
    padding: '0 13px',
    paddingBottom: '13px',
    display: 'block',
    backgroundColor: '#303030',
    boxShadow: '0 1px 4px 0 #000000',
    alignItems: 'center',
    '& .title': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '18px',
      '& div': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  general: {
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary[1300],
  },
  generalContent: {
    margin: theme.spacing(1.0),
    borderRadius: '10px',
    border: `1px solid ${theme.palette.primary[800]}`,
    backgroundColor: theme.palette.primary[1400],
  },
  fullHeigh: {
    height: 'calc(100vh - 306px)',
  },
  headerButton: {
    padding: '10px',
    maxWidth: '40px',
    minWidth: '40px',
    maxHeight: '40px',
    minHeight: '40px',
    color: '#acacac',
  },
  headerDetail: {
    padding: theme.spacing(0.7),
    display: 'inline-grid',
    width: '100%',
    gridTemplateColumns: '[col1] auto [col2] auto',
    borderBottom: `1px solid ${theme.palette.primary[800]}!important`,
  },
  headerDetailText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
  headerDetailButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
  },
  itemTitle: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    paddingLeft: '7px',
  },
  itemSubHeader: {
    flex: '1 1 auto',
  },
  titleOne: {
    fontFamily: 'inter',
    fontSize: '18px!important',
    fontStyle: 'normal',
    color: theme.palette.primary[200],
  },
  titleSecond: {
    fontFamily: 'inter',
    fontSize: '16px',
    fontStyle: 'normal',
    color: theme.palette.primary[500],
  },
  titleSubHeading: {
    fontFamily: 'inter',
    color: theme.palette.primary[400],
    fontSize: '14px',
    fontStyle: 'normal',
  },
  titleBreadcumb: {
    color: theme.palette.primary[500],
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '7px',
  },
});
