import React from 'react';
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { AlertTriangleIcon } from 'components/Icons';
import { useStyles } from './styles';
import PropTypes from 'prop-types';

export const ModalWarning = ({ open, title, content, handleClose }) => {
  const classes = useStyles();

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm">
      <DialogContent className={classes.warningAlert}>
        <div className={classes.warningAlertIcon}>
          <AlertTriangleIcon size={20} />
          <IconButton onClick={handleClose}>
            <FontAwesomeIcon icon={faClose} size="lg" />
          </IconButton>
        </div>
        <Typography className={classes.warningAlertTitle}>{title}</Typography>
        <div className={classes.warningAlertText}>{content}</div>
      </DialogContent>
    </Dialog>
  );
};

ModalWarning.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.node,
  handleClose: PropTypes.func,
};

ModalWarning.defaultProps = {
  open: false,
  title: null,
  content: null,
  handleClose: () => {},
};
