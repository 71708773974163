import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  containerDetail: {
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    bottom: 0,
    backgroundColor: '#2B2B2B',
    borderLeft: `1px solid ${theme.palette.secondary[700]}`,
    zIndex: 1000,
    transform: `translateX('110%')`,
    transition: 'transform 0.3s ease-out',
    overflow: 'hidden',
  },
  header: {
    padding: '10px 20px',
    display: 'block',
    backgroundColor: '#303030',
    boxShadow: '0 1px 4px 0 #000000',
    alignItems: 'center',
    '& .title': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& div': {
        display: 'flex',
        alignItems: 'baseline',
        color: theme.palette.secondary[500],
        '& button': { color: 'white', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' },
      },
    },
  },
  headerButton: {
    padding: '10px',
    maxWidth: '40px',
    minWidth: '40px',
    maxHeight: '40px',
    minHeight: '40px',
    color: '#acacac',
  },
  headerDetail: {
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTabs: {
    minHeight: 'auto',
    '& .MuiTabs-scroller': {
      border: 'none',
      borderRadius: 'inherit',
      padding: 0,
    },
    '& .MuiTabs-flexContainer': {
      '& > :first-child': {
        alignItems: 'flex-start',
        paddingLeft: theme.spacing(0),
      },
      '& > :last-child': {
        borderRight: 'none',
      },
    },
    '& .MuiTab-root': {
      backgroundColor: 'transparent',
      borderRight: `1px solid ${theme.palette.primary[500]}`,
      borderRadius: 'inherit',
      padding: `${theme.spacing(0.2)} ${theme.spacing(0.5)}`,
      maxHeight: '25px',
      minHeight: '25px',
      height: '25px',
      minWidth: 'auto',
      '&:hover': {
        color: theme.palette.secondary[500],
      },
      '&.Mui-selected': {
        color: theme.palette.secondary[500],
        borderRadius: 0,
      },
      '&.Mui-disabled': {
        color: theme.palette.primary[400],
        opacity: 0.4,
      },
    },
  },
});
