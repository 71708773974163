import React from 'react';
import { Svg } from './Svg';
import PropTypes from 'prop-types';

export const CurrencyDollarCircle = ({ size, className }) => (
  <Svg viewBox="0 0 24 25" size={size} className={className}>
    <path
      d="M8.5 14.6788C8.5 15.9674 9.54467 17.0121 10.8333 17.0121H13C14.3807 17.0121 15.5 15.8928 15.5 14.5121C15.5 13.1314 14.3807 12.0121 13 12.0121H11C9.61929 12.0121 8.5 10.8928 8.5 9.51208C8.5 8.13137 9.61929 7.01208 11 7.01208H13.1667C14.4553 7.01208 15.5 8.05675 15.5 9.34542M12 5.51208V7.01208M12 17.0121V18.5121M22 12.0121C22 17.5349 17.5228 22.0121 12 22.0121C6.47715 22.0121 2 17.5349 2 12.0121C2 6.48924 6.47715 2.01208 12 2.01208C17.5228 2.01208 22 6.48924 22 12.0121Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);

CurrencyDollarCircle.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

CurrencyDollarCircle.defaultProps = {
  size: 24,
  className: '',
};
