import React, { useEffect } from 'react';
import { FormControlLabel, Switch, RadioGroup, Radio } from '@mui/material';
import { Loader } from 'components/Loader/Loader';
import { useFormik } from 'formik';
import AccountService from 'services/AccountService';
import PropTypes from 'prop-types';

export const SettingsRetention = ({ account, loading }) => {
  const formik = useFormik({
    initialValues: {
      retentionMonths: 0,
      overrideRetention: false,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      await AccountService.updateAccount(values);
    },
  });

  const handleValueAndSubmit = (item, value) => {
    formik.setFieldValue(item, value);
    formik.handleSubmit();
  };

  useEffect(() => {
    formik.setFieldValue('overrideRetention', account.overrideRetention);
    formik.setFieldValue('retentionMonths', account.retentionMonths);
  }, [account]);

  if (loading) {
    return <Loader loading={loading} height={90} size={32} />;
  }

  return (
    <>
      <FormControlLabel
        onChange={(e) => handleValueAndSubmit('overrideRetention', e.target.checked)}
        control={<Switch color="secondary" />}
        checked={formik.values.overrideRetention}
        value="overrideRetention"
        label="Override Current Retention Policy"
      />
      <RadioGroup onChange={(e) => handleValueAndSubmit('retentionMonths', Number(e.target.value))}>
        <div style={{ display: 'flex' }}>
          <FormControlLabel
            control={<Radio color="secondary" />}
            disabled={!formik.values.overrideRetention}
            checked={formik.values.retentionMonths === 12}
            style={{ marginLeft: 40 }}
            value={12}
            label="12 Months"
          />
          <FormControlLabel
            control={<Radio color="secondary" />}
            disabled={!formik.values.overrideRetention}
            checked={formik.values.retentionMonths === 18}
            style={{ marginLeft: 40 }}
            value={18}
            label="18 Months"
          />
        </div>
        <div style={{ display: 'flex' }}>
          <FormControlLabel
            control={<Radio color="secondary" />}
            disabled={!formik.values.overrideRetention}
            checked={formik.values.retentionMonths === 24}
            style={{ marginLeft: 40, marginTop: 10 }}
            value={24}
            label="2 Years"
          />
          <FormControlLabel
            control={<Radio color="secondary" />}
            disabled={!formik.values.overrideRetention}
            checked={formik.values.retentionMonths === 36}
            style={{ marginLeft: 59, marginTop: 10 }}
            value={36}
            label="3 Years"
          />
        </div>
        <div style={{ display: 'flex' }}>
          <FormControlLabel
            control={<Radio color="secondary" />}
            disabled={!formik.values.overrideRetention}
            checked={formik.values.retentionMonths === 60}
            style={{ marginLeft: 40, marginTop: 10 }}
            value={60}
            label="5 Years"
          />
          <FormControlLabel
            control={<Radio color="secondary" />}
            disabled={!formik.values.overrideRetention}
            checked={formik.values.retentionMonths === 120}
            style={{ marginLeft: 59, marginTop: 10 }}
            value={120}
            label="10 Years"
          />
        </div>
        <div style={{ display: 'flex' }}>
          <FormControlLabel
            control={<Radio color="secondary" />}
            disabled={!formik.values.overrideRetention}
            checked={formik.values.retentionMonths === 0}
            style={{ marginLeft: 40, marginTop: 10 }}
            value={0}
            label="Never Archive"
          />
        </div>
      </RadioGroup>
    </>
  );
};

SettingsRetention.propTypes = {
  account: PropTypes.object,
  loading: PropTypes.bool,
};

SettingsRetention.defaultProps = {
  account: {},
  loading: false,
};
