import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Legend, CartesianGrid, Bar } from 'recharts';
import { useStyles } from '../styles';
import { CalendarModal } from 'components/CalendarModal';
import { Loader } from 'components/Loader';
import PropTypes from 'prop-types';
import GreenIcon from 'images/GreenIcon.svg';
import BillingService from 'services/BillingService';
import moment from 'moment';

export const CreditUsageAnalysis = ({ expanded }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [localFilters, setLocalFilters] = useState({ date_range: null });
  const classes = useStyles();
  const barSize = 40;

  const types = (type) => {
    const options = {
      Processing: 'processing',
      Storage: 'storage',
      Indexing: 'indexing',
      Download: 'download',
      AI: 'ai',
    };
    return options[type];
  };

  const getFiltersGraph = () => {
    const filters = { granularity: 'montly' };
    if (localFilters.date_range != null) {
      const [startDate, endDate] = localFilters.date_range;
      filters.startDate = moment(startDate).format('YYYY-MM-DD');
      filters.endDate = moment(endDate).format('YYYY-MM-DD');
    }
    return filters;
  };

  const getGraphicData = async () => {
    setLoading(true);
    const temp = [];
    const response = await BillingService.getCreditGraphCalc({ ...getFiltersGraph() });
    response?.data?.data.map((item) => {
      const type = types(item.credits_type);
      const monthName = moment(item.monthyear).format('MMM');
      const itemAdded = temp.findIndex((added) => added.name === monthName);

      if (itemAdded >= 0) {
        temp[itemAdded][type] = item.credits;
      } else {
        temp.push({
          name: monthName,
          [type]: item.credits,
        });
      }
      return item;
    });
    setData(temp);
    setLoading(false);
  };

  useEffect(() => {
    getGraphicData();
    return () => {
      setData([]);
    };
  }, [localFilters]);

  return (
    <div className={classes.content}>
      {loading ? (
        <Loader loading={loading} height={57} size={32} />
      ) : (
        <>
          <div className={classes.contentHeader}>
            <div className={classes.tileContent}>
              <div className={classes.tile}>
                <img src={GreenIcon} width="55px" alt="Green Success Icon" />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px', width: '100%' }}>
                <div className={classes.titleOne}>Credit Use and Analysis</div>
                <div style={{ marginTop: '5px' }} className={classes.titleSubHeading}>
                  Overview of the past and current credit use.
                </div>
              </div>
            </div>
          </div>

          {expanded && (
            <div className={classes.contentExpand}>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart
                  width={800}
                  height={250}
                  data={data}
                  style={{
                    backgroundColor: 'transparent',
                  }}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Legend
                    wrapperStyle={{ padding: 5, display: 'flex', justifyContent: 'end' }}
                    formatter={(value) => (
                      <span
                        style={
                          value === 'ai'
                            ? { color: '#fcfcfd', textTransform: 'uppercase' }
                            : { color: '#fcfcfd', textTransform: 'capitalize' }
                        }
                      >
                        {value}
                      </span>
                    )}
                    iconType="circle"
                    verticalAlign="top"
                  />
                  <CartesianGrid horizontal vertical={false} />
                  <Bar dataKey="downloads" stackId="a" fill="#FF5C15" radius={[4, 4, 0, 0]} barSize={barSize} />
                  <Bar dataKey="indexing" stackId="a" fill="#FF7C00" radius={[4, 4, 0, 0]} barSize={barSize} />
                  <Bar dataKey="storage" stackId="a" fill="#FF9100" radius={[4, 4, 0, 0]} barSize={barSize} />
                  <Bar dataKey="ai" stackId="a" fill="#FFA826" radius={[4, 4, 0, 0]} barSize={barSize} />
                  <Bar dataKey="processing" stackId="a" fill="#FFCC80" radius={[4, 4, 0, 0]} barSize={barSize} />
                </BarChart>
              </ResponsiveContainer>
              <div style={{ textAlign: 'center' }}>Month</div>
            </div>
          )}

          <div className={`${classes.borderBottom} ${classes.contentFooter}`}>
            <Button
              onClick={() => setOpenCalendar(true)}
              style={{ color: 'white' }}
              variant="contained"
              color="primary"
              disableElevation
            >
              Filter Dates
            </Button>
          </div>
        </>
      )}
      {openCalendar && (
        <CalendarModal
          rangeDate={localFilters.date_range}
          handleClose={() => setOpenCalendar(false)}
          handleConfirm={(rangeDate) => {
            if (rangeDate.length > 0 && rangeDate[0].startDate !== null && rangeDate[0].endDate !== null) {
              setLocalFilters({ date_range: [rangeDate[0].startDate, rangeDate[0].endDate] });
            }
            setOpenCalendar(false);
          }}
        />
      )}
    </div>
  );
};

CreditUsageAnalysis.propTypes = {
  expanded: PropTypes.bool,
};

CreditUsageAnalysis.defaultProps = {
  expanded: false,
};
