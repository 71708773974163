import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router';
import { Grid, Button, Typography } from '@mui/material';
import { AccountSettingsContainer } from 'components/AccountSettingsContainer/AccountSettingsContainer';
import { AdminHeaderTitle } from 'components/AdminHeaderTitle/AdminHeaderTitle';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { ModalSuccessPayment } from 'smartComponents/ModalCreditsPurchasing';
import { ModalWarning } from 'smartComponents/ModalWarning';
import { PaymentHistory } from './PaymentHistory/PaymentHistory';
import { CreditUsage } from './Credits/CreditUsage';
import { CreditUsageDetail } from './Credits/CreditUsageDetails';
import { useStyles } from './styles';
import AccountService from 'services/AccountService';
import BillingService from 'services/BillingService';

export const Accounting = () => {
  const [creditsChoosen, setCreditsChoosen] = useState('');
  const [openPaymentSuccess, setOpenPaymentSuccess] = useState('');
  const [paymentError, setPaymentError] = useState('');
  const classes = useStyles();
  const location = useLocation();

  // Adding the function to manage the payments query params
  const { setActivePanel, setHeaderPanels, setFullWidth } = useContext(SidebarDetailContext);

  const handlePayment = async () => {
    const query = new URLSearchParams(location.search);
    if (query.has('paymentSuccess') && query.has('tk') && query.has('stps')) {
      const payment = query.get('paymentSuccess');
      if (payment === 'true') {
        // validate and update the payment
        const validatePayment = await BillingService.getPayment(query.get('tk'));
        const { status } = validatePayment.data?.data;
        if (status === 'pending') {
          const billing = await BillingService.updateBill(query.get('tk'), { stps: query.get('stps') });
          const { credits } = billing.data?.data;
          if (billing.data.status === 'ok') {
            // update the credits
            await AccountService.updateCredits(parseFloat(credits));
            setCreditsChoosen(credits);
            setOpenPaymentSuccess(true);
          }
        }
      } else if (payment === 'false') {
        setPaymentError(true);
      }
    }
  };

  const handlePaymentsPage = () => {
    const page = 'payments';
    setHeaderPanels([
      {
        name: page,
        component: PaymentHistory,
        actions: {
          handleClose: () => {
            setActivePanel('');
          },
        },
      },
    ]);
    setFullWidth(true);
    setActivePanel(page);
  };

  const handleCreditsPage = () => {
    const credits = 'credits';
    setHeaderPanels([
      {
        name: credits,
        component: CreditUsage,
        actions: {
          handleClose: () => {
            setActivePanel('');
          },
        },
      },
      {
        name: 'creditsDetail',
        component: CreditUsageDetail,
        actions: {
          handleClose: () => {
            setActivePanel(credits);
          },
        },
      },
    ]);
    setFullWidth(true);
    setActivePanel(credits);
  };

  useEffect(() => {
    handlePayment();
  }, []);

  const RedirectButton = ({ handlePage }) => (
    <Button
      onClick={handlePage}
      style={{ marginTop: '10px' }}
      variant="contained"
      color="primary"
      fullWidth
      disableElevation
    >
      View
    </Button>
  );

  return (
    <AccountSettingsContainer title={<AdminHeaderTitle buttonName="ADMINISRATION" section="Accounting" />}>
      <div className={classes.general}>
        <Grid container>
          <Grid item xs={12} sm={12} lg={5} xl={5}>
            <div className={classes.content}>
              <div className={classes.header}>
                <Typography variant="h5">Purchase History</Typography>
              </div>

              <div className={classes.footer}>
                <Typography>View you purchased credit history.</Typography>
                <RedirectButton handlePage={() => handlePaymentsPage()} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={classes.general}>
        <Grid container>
          <Grid item xs={12} sm={12} lg={5} xl={5}>
            <div className={classes.content}>
              <div className={classes.header}>
                <Typography variant="h5">Credit Use History</Typography>
              </div>

              <div className={classes.footer}>
                <Typography>View your credit use.</Typography>
                <RedirectButton handlePage={() => handleCreditsPage()} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {openPaymentSuccess && (
        <ModalSuccessPayment
          success={openPaymentSuccess}
          credits={creditsChoosen}
          handleClose={() => setOpenPaymentSuccess(false)}
        />
      )}
      {paymentError && (
        <ModalWarning
          handleClose={() => setPaymentError(false)}
          open={paymentError}
          title="Transaction has been cancelled"
          content={
            <>
              <Typography>
                The payment has been cancelled or had an error, please check your details in case you want to proceed
                and try again.
              </Typography>
            </>
          }
        />
      )}
    </AccountSettingsContainer>
  );
};
