import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  content: {
    height: '100vh',
    overflow: 'auto',
    backgroundColor: theme.palette.primary.dark,
  },
  container: {
    display: 'grid',
    height: '100%',
    gridTemplate: `"title-panel title-panel most-active-members" 22% "latest-uploads quick-actions most-active-members" 27% "latest-uploads most-active-projects most-active-members" 38% / 36% 36% 28%`,
    '& > div': {
      padding: '20px 10px 10px 25px',
    },
  },
  titlePanel: {
    border: 'none',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary[1100],
    padding: '0px 0px 0px 20px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    '& > span': {
      marginBottom: '35px',
    },
    marginTop: theme.spacing(0.7),
    marginLeft: theme.spacing(0.7),
    marginRight: theme.spacing(0.7),
  },
  latestUploads: {
    gridArea: 'latest-uploads',
    '& > div': {
      border: `1px solid ${theme.palette.primary[900]}`,
      borderRadius: '10px',
      padding: '10px',
      height: '100%',
      '& ul': {
        marginTop: '25px',
      },
    },
  },
  quickActions: {
    gridArea: 'quick-actions',
    '& > div': {
      marginBottom: theme.spacing(0.5),
      '& button': {
        color: '#B1B1B1',
        backgroundColor: theme.palette.primary[1100],
        border: '.5px solid #515151',
      },
    },
  },
  mostActiveProjects: {
    gridArea: 'most-active-projects',
    '& > div': {
      border: `1px solid ${theme.palette.primary[900]}`,
      borderRadius: '10px',
      padding: '20px',
      height: '100%',
    },
  },
  mostActiveMembers: {
    gridArea: 'most-active-members',
    '& > div': {
      border: 'none',
      borderRadius: '10px',
      padding: '20px',
      height: '100%',
    },
  },
  mActiveMembers: {
    marginTop: theme.spacing(0.7),
    marginLeft: theme.spacing(0.7),
    padding: theme.spacing(1),
    height: 'calc(100% - 5rem)',
  },
  generalContent: {
    margin: theme.spacing(0.7),
    padding: theme.spacing(1),
    borderRadius: '10px',
    border: `1px solid ${theme.palette.primary[900]}`,
  },
  fullHeight: {
    height: 'calc(100% - 5rem)',
  },
  item: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.3),
  },
  section: {
    border: `1px solid ${theme.palette.primary[900]}`,
    borderRadius: 10,
  },
  sectionHeader: {
    margin: theme.spacing(0.7),
  },
  sectionContent: {
    marginRight: theme.spacing(0.7),
    marginLeft: theme.spacing(0.7),
    marginBottom: theme.spacing(0.7),
  },
});
