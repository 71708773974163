import React, { useContext } from 'react';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { CloseIcon } from 'components/Icons';
import { MoreThanIcon } from 'components/Icons/MoreThanIcon';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { useStyles } from './styles';
import { ORGANIZATIONS_TABS } from './lib';
import PropTypes from 'prop-types';

export const OrgDashboardHeaderDetail = ({ data, tab, setTabValue }) => {
  const { config } = useContext(SidebarDetailContext);
  const component = config?.header.find((item) => item.name === 'dashboard');
  const { actions } = component;
  const classes = useStyles();

  return (
    <div className={classes.headerDetail}>
      <div className={classes.header}>
        <div className="title">
          <div>
            <button onClick={() => actions.handleClose()} type="button">
              <Typography fontSize={18} lineHeight="28px" fontWeight="500">
                Orgnaization
              </Typography>
            </button>
            <Typography fontSize={14} lineHeight="20px" fontWeight="500">
              <span style={{ margin: '0 10px', paddingRight: '3px', color: '#B1B1B1' }}>
                <MoreThanIcon size={16} />
              </span>
              {data?.name}
            </Typography>
          </div>
          <Button
            onClick={() => actions.handleClose()}
            className={classes.headerButton}
            color="primary"
            variant="contained"
          >
            <CloseIcon size={15} />
          </Button>
        </div>
        <Tabs className={classes.headerTabs} value={tab}>
          <Tab label="Dashboard" onClick={() => setTabValue(ORGANIZATIONS_TABS.DASHBOARD)} />
          <Tab label="Admins" onClick={() => setTabValue(ORGANIZATIONS_TABS.ADMINS)} />
          <Tab label="Members" onClick={() => setTabValue(ORGANIZATIONS_TABS.MEMBERS)} />
          <Tab label="Teams" onClick={() => setTabValue(ORGANIZATIONS_TABS.TEAMS)} />
          <Tab label="Projects" onClick={() => setTabValue(ORGANIZATIONS_TABS.PROJECTS)} />
          <Tab label="Content" onClick={() => setTabValue(ORGANIZATIONS_TABS.CONTENT)} />
          <Tab label="GIS/Maps" onClick={() => setTabValue(ORGANIZATIONS_TABS.MAPS)} />
          <Tab label="Inspections" onClick={() => setTabValue(ORGANIZATIONS_TABS.INSPECTIONS)} />
          <Tab label="Pending Deletion" onClick={() => setTabValue(ORGANIZATIONS_TABS.PENDING_DELETION)} />
          <Tab label="Action Required" onClick={() => setTabValue(ORGANIZATIONS_TABS.ACTION_REQUIRED)} />
          <Tab label="Settings" onClick={() => setTabValue(ORGANIZATIONS_TABS.SETTINGS)} />
        </Tabs>
      </div>
    </div>
  );
};

OrgDashboardHeaderDetail.propTypes = {
  data: PropTypes.object,
  tab: PropTypes.number,
  setTabValue: PropTypes.func,
};

OrgDashboardHeaderDetail.defaultProps = {
  data: {},
  tab: 0,
  setTabValue: () => {},
};
