import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './styles';
import PropTypes from 'prop-types';

export const SettingsCard = ({ children, title, style }) => {
  const classes = useStyles();

  return (
    <div className={classes.general}>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h5">{title}</Typography>
          </div>
          <div className={classes.sectionContent} style={{ ...style }}>
            {children}
          </div>
        </div>
      </Grid>
    </div>
  );
};

SettingsCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  style: PropTypes.object,
};

SettingsCard.defaultProps = {
  children: null,
  title: '',
  style: {},
};
