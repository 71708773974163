import React, { useEffect, useMemo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { usePagination, useSortBy, useTable } from 'react-table';
import { Card } from 'components/Card/Card';
import { CloseIcon, DownloadCloudIcon, VisaIcon, MasterCardIcon, AmexIcon, MoreThanIcon } from 'components/Icons';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { PaymentHistoryActions } from 'components/BillingActions/PaymentHistoryActions';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { Loader } from 'components/Loader';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import { PaymentHistoryPDF } from 'components/BillingActions/PaymentHistory/PaymentHistoryPDF';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { If } from 'components/If/If';
import { getPayments, setPageFilter } from 'slices/paymentHistoryReducer';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { useStyles } from './styles';
import { Item } from './Item';
import SplashImage from 'images/ContentSplash.png';
import moment from 'moment';

const tableColumns = () => [
  {
    Header: 'Receipt',
    accessor: (row) => {
      const month = row?.cardExpirationMonth < 10 ? `0${row?.cardExpirationMonth}` : row?.cardExpirationMonth;
      const title = `Invoice #${row?.invoiceNo}`;
      const subHeader = `Expiry ${month}/${row?.cardExpirationYear}`;
      return (
        <div style={{ display: 'flex' }}>
          <If cond={row?.cardBrand === 'visa'}>
            <VisaIcon />
          </If>
          <If cond={row?.cardBrand === 'mastercard'}>
            <MasterCardIcon />
          </If>
          <If cond={row?.cardBrand === 'amex'}>
            <AmexIcon />
          </If>
          <Item title={title} subHeader={subHeader} />
        </div>
      );
    },
  },
  {
    Header: 'Purchase Date',
    accessor: (row) => moment(row?.invoiceDate).format('MMM DD, YYYY'),
  },
  {
    Header: 'Purchased By',
    accessor: (row) => <Item title={row?.customerName} subHeader={row?.customerEmail} />,
  },
  {
    Header: 'Amount',
    accessor: (row) => `${row?.amountCurrency} $${(row?.amount / 100).toFixed(2)}`,
  },
  {
    Header: 'Credits',
    accessor: (row) => (row?.credits - 0).toLocaleString('en-US'),
  },
];

let debounceTimeout = null;

export const PaymentHistory = () => {
  const { data, loading, filters, totalCount } = useSelector((state) => state.paymentHistory);
  const { config } = useContext(SidebarDetailContext);
  const columns = useMemo(() => tableColumns(), []);
  const dispatch = useDispatch();
  const classes = useStyles();
  const component = config.header.find((item) => item.name === 'payments');
  const { actions } = component;
  let selected = [];

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: filters.page_size,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  const downloadPayments = async () => {
    let payments = [...data];
    if (payments.length) {
      if (selected.length) {
        payments = payments.filter((payment) => selected.includes(payment.id));
      }
      const blob = await pdf(<PaymentHistoryPDF payments={payments} />).toBlob();
      saveAs(blob, `CartoVid_Purchase_History_${moment(new Date()).format('YYYY-MM-DD_HH:MM:SS')}`);
    }
  };

  const handleRowClick = async (row) => {
    const blob = await pdf(<PaymentHistoryPDF payments={[row]} />).toBlob();
    saveAs(blob, `CartoVid_Purchase_${row?.invoiceNo}_${moment(new Date()).format('YYYY-MM-DD_HH:MM:SS')}`);
  };

  // call the table data
  useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      dispatch(getPayments(filters));
    }, 300);
  }, [filters]);

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  if (loading && !data.length) {
    return <Loader loading={loading} height={250} />;
  }

  const PaymentHistoryContainer = () => (
    <GeneralTable
      table={table}
      rowComponent={PaymentHistoryActions}
      actionFunction={(payment) => handleRowClick(payment)}
      handleSelectedIds={(ids) => {
        selected = [...ids];
      }}
      payment
      selectRow
    />
  );

  const EmptyPaymentsHistoryContainer = () => (
    <WidgetSplash image={SplashImage} alt="Payments Splash" title="There are currently no payments." />
  );

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className="title">
          <div>
            <div className={classes.titleOne}>Administration</div>
            <span className={classes.titleBreadcumb}>
              <MoreThanIcon size={16} />
            </span>
            <div className={classes.titleSubHeading}>Purchase History</div>
          </div>
          <Button
            onClick={() => actions.handleClose()}
            className={classes.headerButton}
            variant="contained"
            color="primary"
          >
            <CloseIcon size={20} />
          </Button>
        </div>
      </div>

      <Card noPadding>
        <div className={classes.general}>
          <div className={classes.headerDetail}>
            <div className={`${classes.headerDetailText} ${classes.titleOne}`}>Purchase History</div>

            <div className={classes.headerDetailButton}>
              <Button
                onClick={() => downloadPayments()}
                startIcon={<DownloadCloudIcon size={22} />}
                disabled={!data?.length}
                variant="contained"
                color="primary"
                size="small"
                disableElevation
              >
                <Typography sx={{ fontSize: 14 }}>Download</Typography>
              </Button>
            </div>
          </div>

          <div className={data.length ? classes.generalContent : `${classes.generalContent} ${classes.fullHeigh}`}>
            {data?.length ? <PaymentHistoryContainer /> : <EmptyPaymentsHistoryContainer />}
          </div>

          <TablePagination table={table} position="end" />
        </div>
      </Card>
    </div>
  );
};
