import React, { useState, useEffect, useContext } from 'react';
import { Typography } from '@mui/material';
import { Loader } from 'components/Loader';
import { useDispatch } from 'react-redux';
import { getProject } from 'slices/adminProjectReducer';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { InfoPanel } from 'pages/Admin/Projects/InfoPanel';
import { useStyles } from './styles';
import ProjectService from 'services/ProjectService';

export const MostActiveProjects = (orgId) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setActivePanel, setHeaderPanels, setFullWidth } = useContext(SidebarContext);
  const { config: configDetail } = useContext(SidebarDetailContext);
  const dispatch = useDispatch();
  const classes = useStyles();

  const getMostActiveProjects = (org) => {
    setLoading(true);
    ProjectService.getProjects({ orgId: { eq: org.orgId } })
      .then((response) => {
        setLoading(false);
        const items = response.data.data;
        setProjects(items.sort((a, b) => b.fileHostedCount - a.fileHostedCount));
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getMostActiveProjects(orgId);
  }, []);

  const handleRowClick = async (rowData) => {
    // get the parent component information from the context
    const parent = configDetail?.header?.find((item) => item.name === 'dashboard');
    dispatch(getProject(rowData.id));
    setHeaderPanels([
      {
        name: 'info',
        component: InfoPanel,
        destination: {
          name: parent.name,
          component: parent.component,
        },
      },
    ]);
    setFullWidth(false);
    setActivePanel('info');
  };

  return (
    <div className={classes.generalContent}>
      <span>
        <Typography fontSize={16} color="#FFFFFF" fontWeight={700} lineHeight="24px" fontFamily="Lato">
          Most Active Projects
        </Typography>
        <Typography fontSize={14} color="#CFCFCF" fontWeight={400} lineHeight="21px" fontFamily="Lato">
          Projects your org owns.
        </Typography>
      </span>
      <hr style={{ margin: '15px 0px', color: '#4B4B4B' }} />
      {loading && <Loader loading={loading} height={50} size={32} />}
      {!loading && (
        <ul style={{ height: '314px', overflow: 'auto' }}>
          {projects.map((proj) => (
            <li
              aria-hidden="true"
              onClick={() => handleRowClick(proj)}
              key={proj.id}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '20px',
                marginRight: '5px',
              }}
            >
              <span style={{ marginRight: '15px', cursor: 'pointer' }}>
                <Typography fontSize={14} color="#FFFFFF" fontWeight={700} lineHeight="21px" fontFamily="Lato">
                  {proj.name}
                </Typography>
                <Typography fontSize={12} color="#CFCFCF" fontWeight={4400} lineHeight="18px" fontFamily="Lato">
                  {proj.description}
                </Typography>
              </span>
              <Typography>{proj.fileHostedCount} Uploads</Typography>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
