import React from 'react';
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { HeartIcon } from 'components/Icons';
import { useStyles } from './styles';
import PropTypes from 'prop-types';

export const ModalSuccessPayment = ({ success, credits, handleClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={success} onClose={handleClose} maxWidth="xs">
      <DialogContent className={classes.successAlert}>
        <div className={classes.successAlertIcons}>
          <HeartIcon size={20} />
          <IconButton onClick={handleClose}>
            <FontAwesomeIcon icon={faClose} size="lg" />
          </IconButton>
        </div>
        <Typography className={classes.successAlertTitle}>Successfully Added Credits</Typography>
        <Typography className={classes.successAlertText}>
          Congratulations. You&apos;ve successfully added&nbsp;
          {(credits - 0).toLocaleString('en-US')} credits to your account.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

ModalSuccessPayment.propTypes = {
  success: PropTypes.bool,
  credits: PropTypes.string,
  handleClose: PropTypes.func,
};

ModalSuccessPayment.defaultProps = {
  success: false,
  credits: '',
  handleClose: () => {},
};
