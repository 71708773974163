import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { Avatar } from 'components/Avatar';
import { Loader } from 'components/Loader';
import { resolveUserInitials } from 'lib/resolve-user-initials';
import { theme } from 'theme';
import { useStyles } from './styles';
import OrganizationService from 'services/OrganizationService';
import moment from 'moment';

export const LastUploads = ({ orgId }) => {
  const [uploads, setUploads] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const getLastestUploads = (id) => {
    setLoading(true);
    OrganizationService.getLatestUploads(id)
      .then((res) => {
        setLoading(false);
        setUploads(res.data.data.data);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getLastestUploads(orgId);
  }, []);

  return (
    <div className={`${classes.generalContent} ${classes.fullHeight}`}>
      <span>
        <Typography fontSize={16} color="#FFFFFF" fontWeight={700} lineHeight="24px" fontFamily="Lato">
          Latest Uploads
        </Typography>
        <Typography fontSize={14} color="#CFCFCF" fontWeight={400} lineHeight="21px" fontFamily="Lato">
          Recent uploads to your org.
        </Typography>
      </span>
      {loading && <Loader loading={loading} height={50} size={32} />}
      {!loading && (
        <ul style={{ height: '100%', overflow: 'auto', margin: '10px 0px' }}>
          {uploads.map((upl) => (
            <li key={upl.id} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
              <div style={{ display: 'flex' }}>
                <span
                  style={{
                    border: `1px solid ${theme.palette.primary[900]}`,
                    padding: '12px',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon icon={faClock} size="md" color="#929295" />
                </span>
                <span style={{ margin: '0px 10px' }}>
                  <Typography fontSize={14} color="#FFFFFF" fontWeight={700} lineHeight="21px" fontFamily="Lato">
                    {moment(upl.updatedAt).format('HH:mm')}
                  </Typography>
                  <Typography fontSize={12} color="#CFCFCF" fontWeight={4400} lineHeight="18px" fontFamily="Lato">
                    {moment(upl.updatedAt).format('YYYY-MM-DD')}
                  </Typography>
                </span>
                <span style={{ marginRight: '20px' }}>
                  <Typography fontSize={14} color="#FFFFFF" fontWeight={700} lineHeight="21px" fontFamily="Lato">
                    {upl.name}
                  </Typography>
                  <Typography fontSize={12} color="#CFCFCF" fontWeight={4400} lineHeight="18px" fontFamily="Lato">
                    {`${upl.uploaders[0]?.firstName} ${upl.uploaders[0]?.lastName}`}
                  </Typography>
                </span>
              </div>
              <Avatar
                initials={resolveUserInitials({
                  firstName: upl.uploaders[0]?.firstName,
                  lastName: upl.uploaders[0]?.lastName,
                })}
                size={35}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
